import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import AccountCheckboxList from "./data_accounts";
import MDButton from "../../../components/MDButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Brightness1Icon from "@mui/icons-material/Brightness1";

const CampaignCheckboxList = ({
  ruleName,
  fetchApiEndpoint,
  updateApiEndpoint,
  userEmail,
  userRole,
  campaignsUpdated,
}) => {
  const [campaigns, setCampaigns] = useState([]);
  const [search, setSearch] = useState("");
  const [modifiedCampaigns, setModifiedCampaigns] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [filterMode, setFilterMode] = useState("ALL");

  const fetchCampaigns = async () => {
    try {
      const response = await axios.post(fetchApiEndpoint, {
        role: userRole,
        user_email: userEmail,
      });
      setCampaigns(response.data);
      // Initialize modifiedAccounts with current statuses
      const initialStatus = response.data.reduce((acc, campaign) => {
        acc[campaign.camp_id] = campaign[ruleName];
        return acc;
      }, {});
      setModifiedCampaigns(initialStatus);
    } catch (error) {
      console.error("Error fetching Campaigns:", error);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, [fetchApiEndpoint, ruleName, userEmail, userRole, campaignsUpdated]);

  const handleCheckboxChange = (id, checked) => {
    setModifiedCampaigns((prev) => ({
      ...prev,
      [id]: checked ? "ACTIVE" : "PAUSED",
    }));
  };

  const handleFilterToggle = () => {
    setFilterMode((prev) => {
      if (prev === "ALL") return "CHECKED";
      if (prev === "CHECKED") return "UNCHECKED";
      return "ALL";
    });
  };

  const handleSave = async () => {
    try {
      await axios.post(updateApiEndpoint, {
        key: "by_campaign",
        rule_name: ruleName,
        user_email: userEmail,
        updates: Object.entries(modifiedCampaigns).map(([id, newStatus]) => ({
          campaign_id: id,
          new_status: newStatus,
        })),
      });
      toast.success("Statuses updated successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
      toast.error("Failed to update statuses.");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      // Удаляем выбор со всех отфильтрованных кампаний
      setModifiedCampaigns((prev) => {
        const newState = { ...prev };
        filteredCampaigns.forEach((campaign) => {
          newState[campaign.camp_id] = "PAUSED";
        });
        return newState;
      });
    } else {
      // Выбираем все отфильтрованные кампании
      setModifiedCampaigns((prev) => {
        const newState = { ...prev };
        filteredCampaigns.forEach((campaign) => {
          newState[campaign.camp_id] = "ACTIVE";
        });
        return newState;
      });
    }
    setSelectAll(!selectAll);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const filteredCampaigns = campaigns.filter((campaign) => {
    const searchLower = search.toLowerCase();
    const nameMatches = campaign.campaign_name.toLowerCase().includes(searchLower);
    const idMatches = campaign.camp_id.toLowerCase().includes(searchLower);

    if (filterMode === "ALL") {
      return nameMatches || idMatches;
    } else if (filterMode === "CHECKED") {
      return (nameMatches || idMatches) && modifiedCampaigns[campaign.camp_id] === "ACTIVE";
    } else if (filterMode === "UNCHECKED") {
      return (
        ((nameMatches || idMatches) && modifiedCampaigns[campaign.camp_id] === "PAUSED") ||
        modifiedCampaigns[campaign.camp_id] === undefined ||
        modifiedCampaigns[campaign.camp_id] === null
      );
    }
  });

  return (
    <Container maxWidth="sm" style={{ marginTop: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "10px",
          gap: 2,
        }}
      >
        <IconButton onClick={handleSelectAll}>
          {selectAll ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
        </IconButton>
        <IconButton onClick={handleFilterToggle}>
          {filterMode === "ALL" ? (
            <Brightness1Icon />
          ) : filterMode === "CHECKED" ? (
            <CheckCircleIcon />
          ) : (
            <CancelIcon />
          )}
        </IconButton>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search by name or ID"
          value={search}
          onChange={handleSearchChange}
          sx={{
            height: 36, // Установите высоту, чтобы она соответствовала высоте кнопки
            "& .MuiInputBase-root": {
              // Это для изменения высоты внутреннего элемента текстового поля
              height: "100%",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "500px",
          overflowY: "auto",
          overflowX: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          sx={{ minWidth: "1200px" }} // Добавляем минимальную ширину больше контейнера
        >
          {filteredCampaigns.map((campaign, index) => (
            <Grid item key={`${campaign.camp_id}-${index}`}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Checkbox
                    checked={modifiedCampaigns[campaign.camp_id] === "ACTIVE"}
                    onChange={(e) => handleCheckboxChange(campaign.camp_id, e.target.checked)}
                    inputProps={{ "aria-label": `checkbox for campaign ${campaign.camp_id}` }}
                  />
                </Grid>
                <Grid item>
                  <Typography fontSize={"0.875rem"}>
                    {campaign.campaign_name}: {campaign.camp_id}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          marginBottom: "10px",
          gap: 2,
        }}
      >
        <Button variant="contained" color="white" onClick={handleSave}>
          Save
        </Button>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarMessage.includes("Failed") ? "error" : "success"}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

CampaignCheckboxList.propTypes = {
  ruleName: PropTypes.any,
  userEmail: PropTypes.any,
  userRole: PropTypes.any,
  fetchApiEndpoint: PropTypes.any,
  updateApiEndpoint: PropTypes.any,
  campaignsUpdated: PropTypes.any,
};

export default CampaignCheckboxList;
