import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "../dropdown_component";
import Box from "@mui/material/Box";
import FilterByColumnNameSearch from "../../task_management/search_by_column_name";
import Typography from "@mui/material/Typography";
import CustomInputField from "../input_field";
import CustomInputFieldMultiline from "../input_field_multiline";
import CustomNumericInputField from "../input_number_field";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import MDInput from "../../../components/MDInput";
import Button from "@mui/material/Button";

function CreateCampaign() {
  const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";
  const API_URL_CAMPAIGN_CREATION =
    "https://fb-campaign-creator-only-campaigns-tmgx45bhsa-uc.a.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const languagesAll = [
    "Abkhaz",
    "Afar",
    "Afrikaans",
    "Akan",
    "Albanian",
    "Amharic",
    "Arabic",
    "Armenian",
    "Assamese",
    "Aymara",
    "Azerbaijani",
    "Bambara",
    "Bashkir",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bihari",
    "Bislama",
    "Bosnian",
    "Breton",
    "Bulgarian",
    "Burmese",
    "Catalan",
    "Chamorro",
    "Chechen",
    "Chinese",
    "Chuvash",
    "Cornish",
    "Corsican",
    "Croatian",
    "Czech",
    "Danish",
    "Divehi",
    "Dutch",
    "Dzongkha",
    "English",
    "Esperanto",
    "Estonian",
    "Ewe",
    "Faroese",
    "Fijian",
    "Finnish",
    "French",
    "Galician",
    "Georgian",
    "German",
    "Greek",
    "Greenlandic",
    "Guarani",
    "Gujarati",
    "Haitian",
    "Hausa",
    "Hebrew",
    "Herero",
    "Hindi",
    "Hiri Motu",
    "Hungarian",
    "Icelandic",
    "Igbo",
    "Indonesian",
    "Interlingua",
    "Interlingue",
    "Inuktitut",
    "Inupiaq",
    "Irish",
    "Italian",
    "Japanese",
    "Javanese",
    "Kannada",
    "Kanuri",
    "Kashmiri",
    "Kazakh",
    "Khmer",
    "Kikuyu",
    "Kinyarwanda",
    "Kirghiz",
    "Kirundi",
    "Komi",
    "Kongo",
    "Korean",
    "Kuanyama",
    "Kurdish",
    "Lao",
    "Latin",
    "Latvian",
    "Limburgish",
    "Lingala",
    "Lithuanian",
    "Luba-Katanga",
    "Luxembourgish",
    "Macedonian",
    "Malagasy",
    "Malay",
    "Malayalam",
    "Maltese",
    "Manx",
    "Maori",
    "Marathi",
    "Marshallese",
    "Mongolian",
    "Nauru",
    "Navajo",
    "Ndebele",
    "Ndonga",
    "Nepali",
    "North Ndebele",
    "Northern Sami",
    "Norwegian",
    "Occitan",
    "Ojibwa",
    "Oriya",
    "Oromo",
    "Ossetian",
    "Pali",
    "Pashto",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Quechua",
    "Romansh",
    "Romanian",
    "Russian",
    "Samoan",
    "Sango",
    "Sanskrit",
    "Serbian",
    "Shona",
    "Sindhi",
    "Sinhala",
    "Slovak",
    "Slovenian",
    "Somali",
    "South Ndebele",
    "Southern Sotho",
    "Spanish",
    "Sundanese",
    "Swahili",
    "Swati",
    "Swedish",
    "Tagalog",
    "Tahitian",
    "Tajik",
    "Tamil",
    "Tatar",
    "Telugu",
    "Thai",
    "Tibetan",
    "Tigrinya",
    "Tonga",
    "Tsonga",
    "Tswana",
    "Turkish",
    "Turkmen",
    "Twi",
    "Uighur",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Venda",
    "Vietnamese",
    "Volapük",
    "Walloon",
    "Welsh",
    "Western Frisian",
    "Wolof",
    "Xhosa",
    "Yiddish",
    "Yoruba",
    "Zhuang",
    "Zulu",
  ];

  const [selectedAccount, setSelectedAccount] = useState("");
  const [accountsAll, setAccountsAll] = useState([]);

  const [selectedPage, setSelectedPage] = useState("");
  const [pagesAll, setPagesAll] = useState([]);

  const [selectedType, setSelectedType] = useState("");
  const typesAll = ["CBO", "ABO", "ABO | BID_CAP", "ABO | COST_CAP", "ADVANTAGE_PLUS"];
  const [selectedStatus, setSelectedStatus] = useState("");
  const statusesAll = ["ACTIVE", "PAUSED"];

  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const defaultDate = tomorrow.toISOString().split("T")[0]; // 'YYYY-MM-DD' format

  const [date, setDate] = useState(defaultDate);
  const [time, setTime] = useState("00:00:00");

  const [selectedGeo, setSelectedGeo] = useState("");
  const geoAll = [
    "AD",
    "AE",
    "AF",
    "AG",
    "AI",
    "AL",
    "AM",
    "AO",
    "AQ",
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ",
    "BA",
    "BB",
    "BD",
    "BE",
    "BF",
    "BG",
    "BH",
    "BI",
    "BJ",
    "BL",
    "BM",
    "BN",
    "BO",
    "BQ",
    "BR",
    "BS",
    "BT",
    "BV",
    "BW",
    "BY",
    "BZ",
    "CA",
    "CC",
    "CD",
    "CF",
    "CG",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CU",
    "CV",
    "CW",
    "CX",
    "CY",
    "CZ",
    "DE",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ER",
    "ES",
    "ET",
    "FI",
    "FJ",
    "FM",
    "FO",
    "FR",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN",
    "GP",
    "GQ",
    "GR",
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM",
    "HN",
    "HR",
    "HT",
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IO",
    "IQ",
    "IR",
    "IS",
    "IT",
    "JE",
    "JM",
    "JO",
    "JP",
    "KE",
    "KG",
    "KH",
    "KI",
    "KM",
    "KN",
    "KP",
    "KR",
    "KW",
    "KY",
    "KZ",
    "LA",
    "LB",
    "LC",
    "LI",
    "LK",
    "LR",
    "LS",
    "LT",
    "LU",
    "LV",
    "LY",
    "MA",
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MH",
    "MK",
    "ML",
    "MM",
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI",
    "NL",
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA",
    "PE",
    "PF",
    "PG",
    "PH",
    "PK",
    "PL",
    "PM",
    "PN",
    "PR",
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RW",
    "SA",
    "SB",
    "SC",
    "SD",
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN",
    "SO",
    "SR",
    "SS",
    "ST",
    "SV",
    "SX",
    "SY",
    "SZ",
    "TC",
    "TD",
    "TF",
    "TG",
    "TH",
    "TJ",
    "TK",
    "TL",
    "TM",
    "TN",
    "TO",
    "TR",
    "TT",
    "TV",
    "TZ",
    "UA",
    "UG",
    "US",
    "UY",
    "UZ",
    "VA",
    "VC",
    "VE",
    "VG",
    "VI",
    "VN",
    "VU",
    "WF",
    "WS",
    "YE",
    "YT",
    "ZA",
    "ZM",
    "ZW",
    "FK",
    "XK",
    "AN",
    "PS",
    "GS",
    "TW",
    "UM",
  ];

  const [campaignsData, setCampaignsData] = useState({});
  const [selectedName, setSelectedName] = useState("");
  const [namesAll, setNamesAll] = useState([]);

  const [selectedFbSpCategory, setSelectedFbSpCategory] = useState("");
  const fbSpCategoriesAll = ["EMPLOYMENT", "HOUSING", "CREDIT", "NONE"];

  const [selectedStage, setSelectedStage] = useState("");
  const [stagesAll, setStagesAll] = useState([]);
  const [selectedOptimEvent, setSelectedOptimEvent] = useState("");
  const [optimEventsAll, setOptimEventsAll] = useState([]);
  const [selectedFbPixel, setSelectedFbPixel] = useState("");
  const [fbPixelsAll, setFbPixelsAll] = useState([]);

  const [inputRFolderNameGD, setInputRFolderNameGD] = useState("");
  const [inputLinkForFB, setInputLinkForFB] = useState("");
  const [inputCampNameComment, setCampNameComment] = useState("");

  const [inputAdjAdCopies, setInputAdjAdCopies] = useState([""]);
  const [inputAdjAdCopy, setInputAdjAdCopy] = useState("");

  const [inputDescription, setInputDescription] = useState("");
  const [inputAdjAdText, setInputAdjAdText] = useState("");
  const [inputDCategory, setInputDCategory] = useState("");

  const [inputAdsPerAdset, setInputAdsPerAdset] = useState("");
  const [inputBudget, setInputBudget] = useState("");
  const [inputBidAmount, setInputBidAmount] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [selectedDealId, setSelectedDealID] = useState("");
  const currentDate = new Date().toLocaleDateString("en-GB").split("/").reverse().join("-");

  const campaignNameView = inputCampNameComment
    ? `${currentDate} - ${
        selectedAccount.split(": ")[0]
      } - ${inputDCategory} - ${selectedType} - ${selectedStage} - ${selectedGeo} - ${selectedLanguage} | ${inputCampNameComment}`
    : `${currentDate} - ${
        selectedAccount.split(": ")[0]
      } - ${inputDCategory} - ${selectedType} - ${selectedStage} - ${selectedGeo} - ${selectedLanguage}`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accountsResponse = await fetch(`${API_URL}/get_accounts_all/`);
        const accountsData = await accountsResponse.json();
        setAccountsAll(accountsData);

        const pageResponse = await fetch(`${API_URL}/get_pages_all/`);
        const pagesData = await pageResponse.json();
        setPagesAll(pagesData);

        const nameResponse = await fetch(`${API_URL}/get_campaigns_fb_ready_to_launch/`);
        const namesData = await nameResponse.json();
        setCampaignsData(namesData);

        const emailsNames = {
          "Artemyusi@gmail.com": "Artem",
          "Vlad0986613794@gmail.com": "Vlad",
          "achudak23@gmail.com": "Anton",
          "nuzhnyias@gmail.com": "Alex",
          "xxxuko@gmail.com": "Anya",
          "Hembarskyi@gmail.com": "Michail",
          "Hembarskyi_user@gmail.com": "Michail",
          "andrew@salesbrush.com": "Andrew",
          "andreylipovskiy0@gmail.com": "Andrey",
          "mcerneva2@gmail.com": "Maryna",
        };

        let filteredCampaignNames;

        if (userRole === "admin") {
          filteredCampaignNames = Object.keys(namesData);
        } else {
          const userName = emailsNames[userEmail];
          filteredCampaignNames = Object.keys(namesData).filter((campaignName) =>
            campaignName.includes(userName)
          );
        }

        setNamesAll(filteredCampaignNames);

        const campaignsConstantsAll = await fetch(`${API_URL}/get_constants_fb_ready_to_launch/`);
        const campaignsConstants = await campaignsConstantsAll.json();
        setStagesAll(campaignsConstants["d___campaign_stages"]);
        setOptimEventsAll(campaignsConstants["fb___optimisation_events"]);
        setFbPixelsAll(campaignsConstants["fb___pixels"]);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedName) {
      const campaignProperties = campaignsData[selectedName];
      console.log(`Campaign data:`, campaignProperties);
      if (campaignProperties) {
        setSelectedLanguage(campaignProperties["language"] || "");

        const adAccountId = campaignProperties["ad_account"] || "";
        const matchedAccount = accountsAll.find((account) => {
          const accountParts = account.split(": ");
          return accountParts[1] === adAccountId;
        });
        setSelectedAccount(matchedAccount || "");

        const fbPage = campaignProperties["fb___page"] || "";
        const matchedPage = pagesAll.find((page) => {
          const pageParts = page.split(": ");
          return pageParts[1] === fbPage;
        });
        setSelectedPage(matchedPage || "");

        if (campaignProperties) {
          setSelectedType(campaignProperties["d___campaign_type"] || "");
          setSelectedStatus("ACTIVE");
          setSelectedGeo(campaignProperties["fb___geo"] || "");
          setInputRFolderNameGD(campaignProperties["r___folder_name__google_drive_"] || "");
          setInputLinkForFB(campaignProperties["fb__link_for_fb"] || "");
          setInputAdjAdCopy(campaignProperties["d___adjusted_ad_copy"]);
          inputAdjAdCopies[0] = campaignProperties["d___adjusted_ad_copy"];
          setInputAdjAdText(campaignProperties["adjusted_ad_text"] || "");
          setSelectedFbSpCategory(campaignProperties["fb___special_category"] || "");
          setSelectedFbPixel(campaignProperties["fb___pixel"] || "");
          setSelectedOptimEvent(campaignProperties["fb___optimisation_event"] || "");
          setSelectedStage(campaignProperties["d___campaign_stage"] || "");
          setInputDCategory(campaignProperties["d___category"] || "");
          setSelectedDealID(campaignProperties["hs_object_id"] || "");
        }
      }
    }
  }, [selectedName, campaignsData]);

  const handleCreateCampaign = () => {
    if (
      !selectedName ||
      !selectedAccount ||
      !selectedLanguage ||
      !selectedGeo ||
      !selectedPage ||
      !inputRFolderNameGD ||
      !inputAdjAdCopy ||
      !inputDescription ||
      !inputAdjAdText ||
      !selectedFbPixel ||
      !selectedOptimEvent ||
      !selectedStage ||
      !inputDCategory ||
      !selectedType ||
      !selectedStatus ||
      !inputLinkForFB
    ) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL_CAMPAIGN_CREATION}/campaign-create/`, {
      // fetch(`${API_URL_CAMPAIGN_CREATION}/campaign-create-test-response/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          schedule_date: date,
          schedule_time: time,
          ad_account: selectedAccount,
          adjusted_ad_text: inputAdjAdText,
          language: selectedLanguage,
          fb___page: selectedPage,
          d___campaign_type: selectedType,
          adset_status: selectedStatus,
          fb___geo: selectedGeo,
          name: campaignNameView,
          comment: inputCampNameComment ? ` | ${inputCampNameComment}` : "",
          r___folder_name__google_drive_: inputRFolderNameGD,
          fb__link_for_fb: inputLinkForFB,
          d___adjusted_ad_copy: inputAdjAdCopy,
          primary_texts: inputAdjAdCopies,
          fb___special_category: selectedFbSpCategory,
          fb___pixel: selectedFbPixel,
          fb___optimisation_event: selectedOptimEvent,
          d___campaign_stage: selectedStage,
          d___category: inputDCategory,
          hs_object_id: selectedDealId,
          description: inputDescription,
          ads_per_adset: inputAdsPerAdset,
          budget: inputBudget,
          bid_cost_amount: inputBidAmount,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Campaign successfully created!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleCreateCampaign}>
            Create campaign
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  const addInputField = () => {
    if (inputAdjAdCopies.length < 5) {
      setInputAdjAdCopies([...inputAdjAdCopies, ""]); // добавляем новое пустое поле
    }
  };

  const removeInputField = (index) => {
    const newInputAdjAdCopies = inputAdjAdCopies.filter((_, i) => i !== index);
    setInputAdjAdCopies(newInputAdjAdCopies);
  };

  const handleInputChange = (index, value) => {
    const newInputAdjAdCopies = [...inputAdjAdCopies];
    newInputAdjAdCopies[index] = value; // обновляем конкретное поле
    setInputAdjAdCopies(newInputAdjAdCopies);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3} display="flex" alignItems="center">
                <img
                  src="https://www.svgrepo.com/show/83242/birdhouse.svg"
                  alt="Image"
                  style={{ width: "40px", height: "40px" }}
                />
                <MDTypography variant="h5">&nbsp;&nbsp;Campaigns creation</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Box flexGrow={1}>
                        <CustomDropdown
                          selectedCategory={selectedName}
                          setSelectedCategory={setSelectedName}
                          categories={namesAll}
                          category_name={"Campaign name"}
                          required={true}
                        />
                      </Box>
                    </Box>
                  </Grid>

                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomDropdown
                          selectedCategory={selectedAccount}
                          setSelectedCategory={setSelectedAccount}
                          categories={accountsAll}
                          category_name={"FB - AD account"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedPage}
                          setSelectedCategory={setSelectedPage}
                          categories={pagesAll}
                          category_name={"FB - page"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedType}
                          setSelectedCategory={setSelectedType}
                          categories={typesAll}
                          category_name={"D - Campaign type"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedLanguage}
                          setSelectedCategory={setSelectedLanguage}
                          categories={languagesAll}
                          category_name={"FB - Language"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedGeo}
                          setSelectedCategory={setSelectedGeo}
                          categories={geoAll}
                          category_name={"FB - Geo"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedFbSpCategory}
                          setSelectedCategory={setSelectedFbSpCategory}
                          categories={fbSpCategoriesAll}
                          category_name={"fb___special_category"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedFbPixel}
                          setSelectedCategory={setSelectedFbPixel}
                          categories={fbPixelsAll}
                          category_name={"fb___pixel"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedOptimEvent}
                          setSelectedCategory={setSelectedOptimEvent}
                          categories={optimEventsAll}
                          category_name={"fb___optimisation_event"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedStage}
                          setSelectedCategory={setSelectedStage}
                          categories={stagesAll}
                          category_name={"d___campaign_stage"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomInputField
                          inputValue={inputDCategory}
                          setInputValue={setInputDCategory}
                          label={"d___category"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/426161/bird.svg"
                            alt="Image"
                            style={{ width: "30px", height: "30px" }}
                          />
                          <MDTypography variant="h6">
                            &nbsp;&nbsp;Schedule created campaign start (Los-Angeles time)
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12} container alignItems="center">
                        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 5 }}>
                          <MDInput type="date" value={date} onChange={handleDateChange} />
                          <MDInput type="time" value={time} onChange={handleTimeChange} />
                          <Button
                            onClick={() => {
                              setDate("");
                              setTime("");
                            }}
                            variant="contained"
                            color="white"
                          >
                            🚫 SKIP
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/426161/bird.svg"
                            alt="Image"
                            style={{ width: "30px", height: "30px" }}
                          />
                          <MDTypography variant="h6">
                            &nbsp;&nbsp;FB link and GoogleDrive settings
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={inputRFolderNameGD}
                          setInputValue={setInputRFolderNameGD}
                          label={"GoogleDrive folder_name or link (https://)"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputFieldMultiline
                          inputValue={inputLinkForFB}
                          setInputValue={setInputLinkForFB}
                          label={"fb__link_for_fb"}
                          multiline
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/426161/bird.svg"
                            alt="Image"
                            style={{ width: "28px", height: "28px" }}
                          />
                          <MDTypography variant="h6">
                            &nbsp;&nbsp;Add short commentary to campaign name if need
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={inputCampNameComment}
                          setInputValue={setCampNameComment}
                          label={"⚠️limit 20, don`t use '-'"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={campaignNameView}
                          setInputValue={() => {}}
                          label={"Campaign Name View (the date and account name may differ)"}
                          required={false}
                          readOnly={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/426161/bird.svg"
                            alt="Image"
                            style={{ width: "28px", height: "28px" }}
                          />
                          <MDTypography variant="h6">&nbsp;&nbsp;Ad Set settings</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: 1Adset/2Ads for CBO, 1Adset/All_Ads for ABO
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputAdsPerAdset}
                          setInputValue={setInputAdsPerAdset}
                          label={"Ads per adset"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: $5
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputBudget}
                          setInputValue={setInputBudget}
                          label={"Daily budget (in $)"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: $1 (Only for BID/COST_CAP)
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputBidAmount}
                          setInputValue={setInputBidAmount}
                          label={"Bid/Cost amount (in $)"}
                          required={false}
                          allowFloat={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <CustomDropdown
                          selectedCategory={selectedStatus}
                          setSelectedCategory={setSelectedStatus}
                          categories={statusesAll}
                          category_name={"Adset status"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/426161/bird.svg"
                            alt="Image"
                            style={{ width: "28px", height: "28px" }}
                          />
                          <MDTypography variant="h6">&nbsp;&nbsp;Ad settings</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12} container alignItems="center">
                        <Grid item xs={12} md={11}>
                          <CustomInputField
                            inputValue={inputAdjAdText}
                            setInputValue={setInputAdjAdText}
                            label={"Headline"}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={11}>
                          <CustomInputFieldMultiline
                            inputValue={inputDescription}
                            setInputValue={setInputDescription}
                            label={"Ad Description"}
                            multiline
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={11}>
                          <CustomInputFieldMultiline
                            inputValue={inputAdjAdCopies[0]}
                            setInputValue={(value) => handleInputChange(0, value)}
                            label={"Ad text"}
                            multiline
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton onClick={() => addInputField(0)}>
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                      {/* Поля для дополнительных текстов */}
                      {inputAdjAdCopies.slice(1).map((inputValue, index) => (
                        <Grid item xs={12} md={12} key={index + 1} container alignItems="center">
                          <Grid item xs={11}>
                            <CustomInputFieldMultiline
                              inputValue={inputValue}
                              setInputValue={(value) => handleInputChange(index + 1, value)} // Индекс увеличен на 1
                              label={`Ad text ${index + 2}`} // Динамическое имя поля
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton onClick={() => addInputField(index + 1)}>
                              <AddIcon />
                            </IconButton>
                            <IconButton onClick={() => removeInputField(index + 1)}>
                              {" "}
                              {/* Передаем текущий индекс + 1 */}
                              <ClearIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>

              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateCampaign;
