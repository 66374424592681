import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
// import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

//Sign in
import SignIn from "layouts/authentication/sign-in/basic/index";
import Analytics from "./layouts/dashboards/analytics";
import CreateCampaign from "./layouts/campaigns/create_campaign";
import CreateReport from "./layouts/reports/create_report";
import { getUserRoutes } from "routes";
import Cover from "./layouts/authentication/sign-up/cover/index";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdateCampaign from "./layouts/campaigns/update_campaign";
import CampaignsALL from "./layouts/campaigns/campaigns_all";
import CampaignsUnderRule from "./layouts/campaigns/campaigns_under_rule";
import CreateKWLIST from "./layouts/kw_analytics_all/create_kw_lists";
import KWAnalytics from "./layouts/dashboards/kw_analytics";
import TablesHistorical from "./layouts/dashboards/historical_tables";
import TablesDailyDa from "./layouts/dashboards/daily_tables_da";
import TablesDailyS1 from "./layouts/dashboards/daily_tables_s1";
import TablesHourly from "./layouts/dashboards/hourly_tables";
import SelectedCategories from "./layouts/task_management/selected_categories";
import ParsedFbLinks from "./layouts/task_management/parsed_fb_links";
import SelectedCategoriesAnalysis from "./layouts/task_management/selected_categories_analysis";
import AntiScam from "./layouts/dashboards/anti_scam";
import LogsActivity from "./layouts/task_management/logs_activity";
import LogsActivityAutorules from "./layouts/logs/autorules_logs";
import CampaignsUnderRuleQueue from "./layouts/campaigns/campaigns_under_rule_queue";
import StopLoss from "./layouts/dashboards/rules/stop_loss";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("isLoggedIn") === "true";
  });
  const [userEmail, setUserEmail] = useState(localStorage.getItem("userEmail"));
  const [userRole, setUserRole] = useState(localStorage.getItem("userRole"));

  const setLoggedInState = (isLoggedIn) => {
    setIsLoggedIn(isLoggedIn);
  };

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const routes = getUserRoutes(userEmail, userRole);
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Dashboard"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          <Route
            path="/authentication/sign-in/basic"
            element={
              <SignIn
                setIsLoggedIn={setIsLoggedIn}
                setUserEmail={setUserEmail}
                setUserRole={setUserRole}
              />
            }
          />
          {/*<Route*/}
          {/*  path="/authentication/sign-up/cover"*/}
          {/*  element={isLoggedIn ? <Cover /> : <Navigate to="/authentication/sign-in/basic" />}*/}
          {/*/>*/}
          <Route
            path="/dashboards/analytics"
            element={isLoggedIn ? <Analytics /> : <Navigate to="/authentication/sign-in/basic" />}
          />
          <Route
            path="/reports/create_report"
            element={
              isLoggedIn ? <CreateReport /> : <Navigate to="/authentication/sign-in/basic" />
            }
          />
          {getRoutes(routes)}
          {/*<Route path="*" element={<Navigate to="/dashboards/analytics" />} />*/}
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="Dashboard"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        <Route path="/authentication/sign-up/cover" element={<Cover />} />
        <Route
          path="/authentication/sign-in/basic"
          element={
            <SignIn
              setIsLoggedIn={setIsLoggedIn}
              setUserEmail={setUserEmail}
              setUserRole={setUserRole}
            />
          }
        />
        <Route
          path="/dashboards/analytics"
          element={isLoggedIn ? <Analytics /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/campaigns/create_campaign"
          element={
            isLoggedIn ? <CreateCampaign /> : <Navigate to="/authentication/sign-in/basic" />
          }
        />
        <Route
          path="/campaigns/update_campaign"
          element={
            isLoggedIn ? <UpdateCampaign /> : <Navigate to="/authentication/sign-in/basic" />
          }
        />
        <Route
          path="/campaigns/my_campaigns"
          element={isLoggedIn ? <CampaignsALL /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/rules/stop_loss_1"
          element={isLoggedIn ? <StopLoss /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/rules/campaigns_under_rule"
          element={
            isLoggedIn ? <CampaignsUnderRule /> : <Navigate to="/authentication/sign-in/basic" />
          }
        />
        <Route
          path="/rules/activation_queue"
          element={
            isLoggedIn ? (
              <CampaignsUnderRuleQueue />
            ) : (
              <Navigate to="/authentication/sign-in/basic" />
            )
          }
        />
        <Route
          path="/reports/create_report"
          element={isLoggedIn ? <CreateReport /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/kw_management/kw_analytics"
          element={isLoggedIn ? <KWAnalytics /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/kw_management/create_kw_lists"
          element={isLoggedIn ? <CreateKWLIST /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/dashboards/historical_tables"
          element={
            isLoggedIn ? <TablesHistorical /> : <Navigate to="/authentication/sign-in/basic" />
          }
        />
        <Route
          path="/dashboards/daily_tables_da"
          element={isLoggedIn ? <TablesDailyDa /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/dashboards/daily_tables_s1"
          element={isLoggedIn ? <TablesDailyS1 /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/dashboards/hourly_tables"
          element={isLoggedIn ? <TablesHourly /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/task_management/selected_categories"
          element={
            isLoggedIn ? <SelectedCategories /> : <Navigate to="/authentication/sign-in/basic" />
          }
        />
        <Route
          path="/task_management/selected_categories_analysis"
          element={
            isLoggedIn ? (
              <SelectedCategoriesAnalysis />
            ) : (
              <Navigate to="/authentication/sign-in/basic" />
            )
          }
        />
        <Route
          path="/task_management/parsed_fb_links"
          element={isLoggedIn ? <ParsedFbLinks /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/auto_rules/anti_scam"
          element={isLoggedIn ? <AntiScam /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/manage_campaigns/campaigns_all"
          element={isLoggedIn ? <CampaignsALL /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/manage_logs/logs_activity"
          element={isLoggedIn ? <LogsActivity /> : <Navigate to="/authentication/sign-in/basic" />}
        />
        <Route
          path="/auto_rules/logs_activity_auto_rules"
          element={
            isLoggedIn ? <LogsActivityAutorules /> : <Navigate to="/authentication/sign-in/basic" />
          }
        />
        {getRoutes(routes)}
      </Routes>
      <ToastContainer />
    </ThemeProvider>
  );
}
