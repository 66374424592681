import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import "react-toastify/dist/ReactToastify.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MDTypography from "../../../components/MDTypography";

function LogsActivity() {
  const Tips = [
    "This table shows a log of all actions that have been performed.",
    "Here you can view data related to the creation, updates, and scaling of campaigns.",
    "It tracks changes in the statuses of auto-rules.",
    "Any updates to the values of auto-rules are also reflected here.",
    "Modifications in the 'own_research' table are recorded and displayed.",
  ];

  const renderTips = (tipsList) => {
    return tipsList.map((item, key) => {
      const itemKey = `element-${key}`;

      return (
        <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
            {item}
          </MDTypography>
        </MDBox>
      );
    });
  };

  const [tableData, setTableData] = useState({
    columns: [
      {
        Header: "timestamp",
        accessor: "timestamp",
        width: "10%",
      },
      {
        Header: "date",
        accessor: "date",
        width: "5%",
      },
      {
        Header: "user_name",
        accessor: "user_name",
        width: "5%",
        Cell: ({ value }) => renderUserName(value),
      },
      {
        Header: "task_name",
        accessor: "task_name",
        width: "10%",
      },
      {
        Header: "column_id",
        accessor: "column_id",
        width: "5%",
      },
      {
        Header: "column_name",
        accessor: "column_name",
        width: "5%",
        Cell: ({ value }) => renderColumnName(value),
      },
      {
        Header: "changes_old_value",
        accessor: "changes_old_value",
        height: "100px",
        width: "30%",
        Cell: ({ value }) => renderOldValue(value),
      },
      {
        Header: "changes_new_value",
        accessor: "changes_new_value",
        height: "100px",
        width: "30%",
        Cell: ({ value }) => renderNewValue(value),
      },
    ],
    rows: [],
  });

  const userColors = {
    Artem_N: { color: "blue", borderColor: "lightblue" },
    Vlad_A: { color: "purple", borderColor: "lavender" },
    Anton_C: { color: "green", borderColor: "lightgreen" },
    Alex_N: { color: "orange", borderColor: "peachpuff" },
    Anya_P: { color: "#FF50BF", borderColor: "lightpink" },
    Andrew_M: { color: "brown", borderColor: "tan" },
    Michael_H: { color: "plum", borderColor: "lightgray" },
    UNNAMED: { color: "#ffff00", borderColor: "yellow" },
  };

  const renderUserName = (userName) => {
    const { color, borderColor } = userColors[userName] || {
      color: "black",
      borderColor: "lightgray",
    };

    return (
      <span
        style={{
          color,
          border: `1px solid ${borderColor}`,
          borderRadius: "4px",
          padding: "2px 4px",
          margin: "0 2px",
        }}
      >
        {userName}
      </span>
    );
  };

  const renderOldValue = (value) => (
    <span style={{ color: "#FF002A", fontWeight: "bold" }}>{value}</span>
  );
  const renderNewValue = (value) => (
    <span style={{ color: "#05AC31", fontWeight: "bold" }}>{value}</span>
  );
  const renderColumnName = (value) => <span style={{ fontWeight: "bold" }}>{value}</span>;

  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/logs_activity/"
        );
        const rows = response.data.map((row) => ({
          timestamp: row.timestamp,
          date: row.date,
          user_name: row.user_name,
          task_name: row.task_name,
          column_id: row.column_id,
          column_name: row.column_name,
          changes_old_value: row.changes_old_value,
          changes_new_value: row.changes_new_value.split(",").join(", "), // Преобразуйте в строку
        }));
        setTableData((prevState) => ({ ...prevState, rows: rows }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filteredData = tableData.rows.filter((row) =>
    Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} mt={6}>
        <MDTypography variant="h5">Action logs</MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
          {renderTips(Tips)}
        </MDBox>
      </MDBox>
      <MDBox mt={6} mb={3}>
        <DataTable
          table={{
            ...tableData,
            rows: filteredData,
          }}
          canSearch
          searchValue={search}
          onSearchChange={setSearch}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default LogsActivity;
