import LogOut from "layouts/authentication/logout";

import CreateCampaign from "layouts/campaigns/create_campaign";
import CreateReport from "layouts/reports/create_report";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import GridViewIcon from "@mui/icons-material/GridView";
import AddTaskIcon from "@mui/icons-material/AddTask";
import VpnKeyIcon from "@mui/icons-material/VpnKey";

import setIsLoggedIn from "./layouts/authentication/sign-in/basic/index";
import Calendar from "layouts/applications/calendar";
import Cover from "./layouts/authentication/sign-in/cover";
import AntiScam from "./layouts/dashboards/anti_scam";
import CampaignsALL from "./layouts/campaigns/campaigns_all";
import TablesHistorical from "./layouts/dashboards/historical_tables";
import TablesDailyDa from "./layouts/dashboards/daily_tables_da";
import TablesHourly from "./layouts/dashboards/hourly_tables";
import TablesDailyS1 from "./layouts/dashboards/daily_tables_s1";
import SelectedCategories from "./layouts/task_management/selected_categories";
import LogsActivity from "./layouts/task_management/logs_activity";
import ParsedFbLinks from "./layouts/task_management/parsed_fb_links";
import KWAnalytics from "./layouts/dashboards/kw_analytics";
import CreateKWLIST from "./layouts/kw_analytics_all/create_kw_lists";
import UpdateCampaign from "./layouts/campaigns/update_campaign";
import LogsActivityAutorules from "./layouts/logs/autorules_logs";
import GavelIcon from "@mui/icons-material/Gavel";
import CampaignsUnderRuleQueue from "./layouts/campaigns/campaigns_under_rule_queue";
import StopLoss from "./layouts/dashboards/rules/stop_loss";
import StopLoss2 from "./layouts/dashboards/rules/stop_loss_2";
import S24V2_1 from "./layouts/dashboards/rules/s_24_V_2";
import S24V3_1 from "./layouts/dashboards/rules/s_24_V_3";
import StopLoss1_v2 from "./layouts/dashboards/rules/stop_loss_1_v2";
import ScaleRule from "./layouts/dashboards/rules/scale";
import AdsRule from "./layouts/dashboards/rules/ads";
import S24V1_1 from "./layouts/dashboards/rules/s_24_V_1";
import ScaleCampaign from "./layouts/campaigns/create_campaign/create_campaign_scale";
import GenerateLink from "./layouts/campaigns/create_campaign/generate_link";
import GenerateLinkS1 from "./layouts/campaigns/create_campaign/generate_link_s1";
// My constants
const userEmail = localStorage.getItem("userEmail");

export const getUserRoutes = (userEmail, userRole) => {
  const baseRoutes = [
    {
      type: "collapse",
      name: userEmail,
      key: "user",
      icon: <Icon fontSize="large">manage_accounts</Icon>,
      collapse: [
        {
          name: "Logout",
          key: "logout",
          route: "/authentication/logout",
          component: <LogOut setIsLoggedIn={setIsLoggedIn} />,
        },
        {
          name: "Calendar",
          key: "calendar",
          route: "/applications/calendar",
          component: <Calendar userEmail={userEmail} />,
        },
      ],
    },
    { type: "divider", key: "divider-0" },
    { type: "title", title: "Pages", key: "title-pages" },
    {
      type: "collapse",
      name: "FB management",
      key: "campaigns",
      icon: <Icon fontSize="medium">assignment</Icon>,
      collapse: [
        {
          name: "Generate Link DA",
          key: "generate_link_DA",
          route: "/campaigns/generate_link_DA",
          component: <GenerateLink />,
        },
        {
          name: "Generate Link S1",
          key: "generate_link_S1",
          route: "/campaigns/generate_link_S1",
          component: <GenerateLinkS1 />,
        },
        {
          name: "Create campaign",
          key: "create_campaign",
          route: "/campaigns/create_campaign",
          component: <CreateCampaign />,
        },
        {
          name: "Update campaign",
          key: "update_campaign",
          route: "/campaigns/update_campaign",
          component: <UpdateCampaign />,
        },
        {
          name: "Scale campaign",
          key: "scale_campaign",
          route: "/campaigns/scale_campaign",
          component: <ScaleCampaign />,
        },
        {
          name: "My campaigns",
          key: "my_campaigns",
          route: "/campaigns/my_campaigns",
          component: <CampaignsALL />,
        },
        {
          name: "Scheduled campaigns",
          key: "scheduled_campaigns",
          route: "/campaigns/scheduled_campaigns",
          component: <CampaignsUnderRuleQueue />,
        },
        {
          name: "Actions logs",
          key: "logs_activity",
          route: "/manage_logs/logs_activity",
          component: <LogsActivity />,
        },
      ],
    },
    {
      type: "collapse",
      name: "AutoRules",
      key: "auto_rules",
      icon: <GavelIcon />,
      collapse: [
        {
          name: "Logs AutoRules",
          key: "logs_activity_auto_rules",
          route: "/manage_logs/logs_activity_auto_rules",
          component: <LogsActivityAutorules />,
        },
        {
          name: "Stop_loss_1 v.2",
          key: "stop_loss_1_v2",
          route: "/rules/stop_loss_1_v2",
          component: <StopLoss1_v2 />,
        },
        {
          name: "Stop_loss_2",
          key: "stop_loss_2",
          route: "/rules/stop_loss_2",
          component: <StopLoss2 />,
        },
        {
          name: "S-24 V1",
          key: "s24_v_1",
          route: "/rules/S24_v_1",
          component: <S24V1_1 />,
        },
        {
          name: "S-24 V2",
          key: "s24_v_2",
          route: "/rules/S24_v_2",
          component: <S24V2_1 />,
        },
        {
          name: "S-24 V3",
          key: "s24_v_3",
          route: "/rules/S24_v_3",
          component: <S24V3_1 />,
        },
        {
          name: "Scale",
          key: "Scale",
          route: "/rules/scale",
          component: <ScaleRule />,
        },
        {
          name: "Ads",
          key: "Ads",
          route: "/rules/ads",
          component: <AdsRule />,
        },
        // {
        //   name: "Activation queue",
        //   key: "activation_queue",
        //   route: "/rules/activation_queue",
        //   component: <CampaignsUnderRuleQueue />,
        // },
      ],
    },
    {
      type: "collapse",
      name: "KW management",
      key: "kw_management",
      icon: <VpnKeyIcon />,
      collapse: [
        {
          name: "KWAnalytics",
          key: "KW_Analytics",
          route: "/kw_management/kw_analytics",
          component: <KWAnalytics />,
        },
        {
          name: "KW settings",
          key: "create_kw_lists",
          route: "/kw_management/create_kw_lists",
          component: <CreateKWLIST />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Dashboards",
      key: "dashboards",
      icon: <GridViewIcon />,
      collapse: [
        {
          name: "Historical tables",
          key: "historical_tables",
          route: "/dashboards/historical_tables",
          component: <TablesHistorical />,
        },
        {
          name: "Daily tables DA",
          key: "daily_tables_da",
          route: "/dashboards/daily_tables_da",
          component: <TablesDailyDa />,
        },
        {
          name: "Daily tables S1",
          key: "daily_tables_s1",
          route: "/dashboards/daily_tables_s1",
          component: <TablesDailyS1 />,
        },
        {
          name: "Hourly tables",
          key: "hourly_tables",
          route: "/dashboards/hourly_tables",
          component: <TablesHourly />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Task management",
      key: "manage_tasks",
      icon: <AddTaskIcon />,
      collapse: [
        {
          name: "Partners Research",
          key: "partners_research",
          route: "/task_management/partners_research",
          component: <SelectedCategories />,
        },
        // {
        //   name: "Task manage 2",
        //   key: "selected_categories_analysis",
        //   route: "/task_management/selected_categories_analysis",
        //   component: <SelectedCategoriesAnalysis />,
        // },
        {
          name: "Own Research",
          key: "own_research",
          route: "/task_management/own_research",
          component: <ParsedFbLinks />,
        },
      ],
    },
  ];

  if (userRole === "admin") {
    baseRoutes.push(
      { type: "divider", key: "divider-1" },
      { type: "title", title: "Admin only", key: "title-adm" },
      {
        type: "collapse",
        name: "Anti Scam",
        key: "anti_scam",
        icon: <Icon fontSize="medium">rule</Icon>,
        collapse: [
          {
            name: "Anti Scam",
            key: "auto_rules",
            route: "/auto_rules/anti_scam",
            component: <AntiScam />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Manage campaigns",
        key: "manage_campaigns",
        icon: <AddModeratorIcon />,
        collapse: [
          {
            name: "All campaigns",
            key: "campaigns_all",
            route: "/manage_campaigns/campaigns_all",
            component: <CampaignsALL />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Reports",
        key: "reports",
        icon: <Icon fontSize="medium">grading</Icon>,
        collapse: [
          {
            name: "Create report",
            key: "create_report",
            route: "/reports/create_report",
            component: <CreateReport />,
          },
        ],
      },
      {
        type: "collapse",
        name: "IN PROGRESS",
        key: "in_progress",
        icon: <VpnKeyIcon />,
        collapse: [
          {
            name: "DISABLED Stop_loss_1",
            key: "stop_loss_1",
            route: "/rules/stop_loss_1",
            component: <StopLoss />,
          },
        ],
      }
    );
  }

  if (userEmail === "xxxuko@gmail.com") {
    baseRoutes.push(
      { type: "divider", key: "divider-1" },
      { type: "title", title: "Me only", key: "title-adm" },
      {
        type: "collapse",
        name: "Users settings",
        key: "user-settings",
        icon: <Icon fontSize="medium">rule</Icon>,
        collapse: [
          {
            name: "Add new user",
            key: "sign-up",
            route: "/authentication/sign-up/cover",
            component: <Cover />,
          },
          // {
          //   name: "All users",
          //   key: "all",
          //   route: "/users/all",
          //   component: <Cover />,
          // },
        ],
      }
    );
  }
  return baseRoutes;
};
