import React, { useEffect, useState } from "react";
import MDTypography from "../../../components/MDTypography";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import MDBox from "../../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "./styles.css";
import { CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomDropdown from "../dropdown_component";
import Box from "@mui/material/Box";
import FilterByColumnNameSearch from "../../task_management/search_by_column_name";
import Typography from "@mui/material/Typography";
import CustomInputField from "../input_field";
import CustomInputFieldMultiline from "../input_field_multiline";
import CustomNumericInputField from "../input_number_field";
import Button from "@mui/material/Button";
import { languagesAll } from "./constants_update";
import { geoAll } from "./constants_update";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import MDInput from "../../../components/MDInput";

function UpdateCampaign() {
  const API_URL = "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app";
  const API_URL_UPDATE = "https://fb-campaign-creator-only-campaigns-tmgx45bhsa-uc.a.run.app";
  const API_URL_GET_DATA = "https://fb-campaign-creator-get-data-1049948018753.us-central1.run.app";
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const [inputRFolderNameGD, setInputRFolderNameGD] = useState("");
  const [inputAdsPerAdset, setInputAdsPerAdset] = useState("");

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const [selectedCampaignName, setSelectedCampaignName] = useState("");
  const [CampaignNamesAll, setCampaignNamesAll] = useState([]);

  const [selectedAdsetName, setSelectedAdsetName] = useState("");
  const [AdsetNamesAll, setAdsetNamesAll] = useState([]);
  const [filteredAdsets, setFilteredAdsets] = useState([]);

  const [selectedType, setSelectedType] = useState("");
  const typesAll = [
    "CBO",
    "ABO",
    "CBO | BID_CAP",
    "ABO | BID_CAP",
    "CBO | COST_CAP",
    "ABO | COST_CAP",
    "ADVANTAGE_PLUS",
  ];
  const [selectedStatus, setSelectedStatus] = useState("");
  const statusesAll = ["ACTIVE", "PAUSED"];

  const [selectedAccount, setSelectedAccount] = useState("");
  const [accountsAll, setAccountsAll] = useState([]);

  const [selectedPage, setSelectedPage] = useState("");
  const [pagesAll, setPagesAll] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [selectedGeo, setSelectedGeo] = useState("");

  const [selectedStage, setSelectedStage] = useState("");
  const [stagesAll, setStagesAll] = useState(["L1", "L2"]);
  const [selectedOptimEvent, setSelectedOptimEvent] = useState("");
  const [optimEventsAll, setOptimEventsAll] = useState([]);
  const [selectedFbPixel, setSelectedFbPixel] = useState("");
  const [fbPixelsAll, setFbPixelsAll] = useState([]);
  const [inputLinkForFB, setInputLinkForFB] = useState("");
  const [inputAdjAdText, setInputAdjAdText] = useState("");
  const [inputAdjAdCopy, setInputAdjAdCopy] = useState("");
  const [inputAdjAdCopies, setInputAdjAdCopies] = useState([""]);
  const [inputDescription, setInputDescription] = useState("");
  const [inputBudget, setInputBudget] = useState("");
  const [inputBidAmount, setInputBidAmount] = useState("");

  const today = new Date();
  const tomorrow = new Date(today.setDate(today.getDate() + 1));
  const defaultDate = tomorrow.toISOString().split("T")[0]; // 'YYYY-MM-DD' format

  const [date, setDate] = useState(defaultDate);
  const [time, setTime] = useState("00:00:00");

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const accountsResponse = await fetch(`${API_URL}/get_accounts_all/`);
        const accountsData = await accountsResponse.json();
        setAccountsAll(accountsData);

        const pageResponse = await fetch(`${API_URL}/get_pages_all/`);
        const pagesData = await pageResponse.json();
        setPagesAll(pagesData);

        const campaignsConstantsAll = await fetch(`${API_URL}/get_constants_fb_ready_to_launch/`);
        const campaignsConstants = await campaignsConstantsAll.json();
        // setStagesAll(campaignsConstants["d___campaign_stages"]);
        setOptimEventsAll(campaignsConstants["fb___optimisation_events"]);
        setFbPixelsAll(campaignsConstants["fb___pixels"]);
        const newStages = campaignsConstants["d___campaign_stages"];
        const combinedStages = Array.from(new Set([...stagesAll, ...newStages]));
        setStagesAll(combinedStages);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${API_URL}/get_campaigns_names_adsets_types/`);
        const data = await response.json();

        const emailsNames = {
          "Artemyusi@gmail.com": "Artem",
          "Vlad0986613794@gmail.com": "Vlad",
          "achudak23@gmail.com": "Anton",
          "nuzhnyias@gmail.com": "Alex",
          "xxxuko@gmail.com": "Anya",
          "Hembarskyi@gmail.com": "Michail",
          "Hembarskyi_user@gmail.com": "Michail",
          "andrew@salesbrush.com": "Andrew",
          "andreylipovskiy0@gmail.com": "Andrey",
          "mcerneva2@gmail.com": "Maryna",
        };

        let filteredCampaignNames;
        let filteredAdsetNames;

        if (userRole === "admin") {
          // Extract unique values from the data for admin role
          filteredCampaignNames = [...new Set(data.map((item) => item.campaign_id_name))];
          filteredAdsetNames = [...new Set(data.map((item) => item.adset_id_name))];
        } else {
          const userName = emailsNames[userEmail];
          // Filter campaign and adset names based on the user's name
          filteredCampaignNames = [
            ...new Set(
              data
                .map((item) => item.campaign_id_name)
                .filter((campaignName) => campaignName.includes(userName))
            ),
          ];
          filteredAdsetNames = [
            ...new Set(
              data
                .map((item) => item.adset_id_name)
                .filter((adsetName) => adsetName.includes(userName))
            ),
          ];
        }

        // Update state with filtered values
        setCampaignNamesAll(filteredCampaignNames);
        setAdsetNamesAll(filteredAdsetNames);
      } catch (error) {
        setResponseMessage(`Error: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCampaignName) {
      const campaignId = selectedCampaignName.split(" | ")[0];
      const filtered = AdsetNamesAll.filter((adset) => adset.includes(`(${campaignId})`));
      setFilteredAdsets(filtered);
    } else {
      setFilteredAdsets([]);
    }
  }, [selectedCampaignName, setFilteredAdsets]);

  useEffect(() => {
    const fetchCampaignDataG = async () => {
      if (selectedCampaignName) {
        // Making the API request
        try {
          const response = await fetch(`${API_URL_GET_DATA}/campaign_get_data/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_email: userEmail, // Assume userEmail is defined somewhere
              properties: {
                campaign_id: selectedCampaignName,
              },
            }),
          });

          const data = await response.json();

          // Assigning the returned values to state variables
          setSelectedType(data.campaign_type);
          setSelectedLanguage(data.language);
          setSelectedGeo(data.geo);
          setSelectedStage(data.stage);
          setSelectedAccount(data.account_id);
          setSelectedFbPixel(data.pixel_id);
          setSelectedOptimEvent(data.custom_event_type);
          setSelectedPage(data.page_id);
          setInputLinkForFB(data.fb_link_for_fb);
          setInputAdjAdText(data.headline);
          setInputDescription(data.description);
          setInputAdjAdCopy(data.ad_text);
          inputAdjAdCopies[0] = inputAdjAdCopy;
        } catch (error) {
          console.error("Error fetching campaign data:", error);
        }
      } else {
        setFilteredAdsets([]);
      }
    };

    fetchCampaignDataG();
  }, [selectedCampaignName]);

  const handleUpdateCampaign = () => {
    // Проверяем, что заполнены хотя бы необходимые поля
    if (!inputRFolderNameGD || !selectedCampaignName || !selectedStatus || !selectedType) {
      toast.error("Please fill all required fields");
      return;
    }

    setIsLoading(true);
    fetch(`${API_URL_UPDATE}/campaign_update/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        user_email: userEmail,
        properties: {
          schedule_date: date,
          schedule_time: time,
          campaign_id: selectedCampaignName,
          adset_id: selectedAdsetName,
          r___folder_name__google_drive_: inputRFolderNameGD,
          ads_per_adset: inputAdsPerAdset,
          campaign_type: selectedType,
          ad_account: selectedAccount,
          fb__link_for_fb: inputLinkForFB,
          headline: inputAdjAdText,
          primary_text: inputAdjAdCopy,
          primary_texts: inputAdjAdCopies,
          fb___page: selectedPage,
          description: inputDescription,
          fb___geo: selectedGeo,
          fb___optimisation_event: selectedOptimEvent,
          fb___pixel: selectedFbPixel,
          language: selectedLanguage,
          d___campaign_stage: selectedStage,
          adset_status: selectedStatus,
          bid_cost_amount: inputBidAmount,
          daily_budget: inputBudget,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setResponseMessage(JSON.stringify(data, null, 2));
        toast.success("Campaign successfully updated!");
      })
      .catch((error) => {
        setResponseMessage(`Error: ${error.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const ButtonList = () => {
    return (
      <div className="button-list-container">
        <div className="button-list-inner-container">
          <button className="button-list-button" onClick={handleUpdateCampaign}>
            Update campaign
          </button>
        </div>
      </div>
    );
  };

  const ResponseMessage = () => {
    return (
      <div className="response-message-container">
        {isLoading ? (
          <div className="loading-spinner">
            <CircularProgress color="success" />
          </div>
        ) : (
          <Card className="response-message" sx={{ overflow: "auto" }}>
            <pre>{responseMessage}</pre>
          </Card>
        )}
      </div>
    );
  };

  const addInputField = () => {
    if (inputAdjAdCopies.length < 5) {
      setInputAdjAdCopies([...inputAdjAdCopies, ""]); // добавляем новое пустое поле
    }
  };

  const removeInputField = (index) => {
    const newInputAdjAdCopies = inputAdjAdCopies.filter((_, i) => i !== index);
    setInputAdjAdCopies(newInputAdjAdCopies);
  };

  const handleInputChange = (index, value) => {
    const newInputAdjAdCopies = [...inputAdjAdCopies];
    newInputAdjAdCopies[index] = value; // обновляем конкретное поле
    setInputAdjAdCopies(newInputAdjAdCopies);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2} mb={3} display="flex" alignItems="center">
                <img
                  src="https://www.svgrepo.com/show/4659/update.svg"
                  alt="Image"
                  style={{ width: "40px", height: "40px" }}
                />
                <MDTypography variant="h5">&nbsp;&nbsp;Update campaign</MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2} direction="column" alignItems="stretch">
                  <MDBox p={2}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                        <CustomDropdown
                          selectedCategory={selectedCampaignName}
                          setSelectedCategory={setSelectedCampaignName}
                          categories={CampaignNamesAll}
                          category_name={"Campaign ID | Name"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: New adset
                        </Typography>
                        <CustomDropdown
                          selectedCategory={selectedAdsetName}
                          setSelectedCategory={setSelectedAdsetName}
                          categories={filteredAdsets}
                          category_name={"Adset ID | Name"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomDropdown
                          selectedCategory={selectedAccount}
                          setSelectedCategory={setSelectedAccount}
                          categories={accountsAll}
                          category_name={"FB - AD account"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomDropdown
                          selectedCategory={selectedPage}
                          setSelectedCategory={setSelectedPage}
                          categories={pagesAll}
                          category_name={"FB - page"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedType}
                          setSelectedCategory={setSelectedType}
                          categories={typesAll}
                          category_name={"D - Campaign type"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedLanguage}
                          setSelectedCategory={setSelectedLanguage}
                          categories={languagesAll}
                          category_name={"FB - Language"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedGeo}
                          setSelectedCategory={setSelectedGeo}
                          categories={geoAll}
                          category_name={"FB - Geo"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedFbPixel}
                          setSelectedCategory={setSelectedFbPixel}
                          categories={fbPixelsAll}
                          category_name={"fb___pixel"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedOptimEvent}
                          setSelectedCategory={setSelectedOptimEvent}
                          categories={optimEventsAll}
                          category_name={"fb___optimisation_event"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CustomDropdown
                          selectedCategory={selectedStage}
                          setSelectedCategory={setSelectedStage}
                          categories={stagesAll}
                          category_name={"d___campaign_stage"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/219650/leaves-plant.svg"
                            alt="Image"
                            style={{ width: "28px", height: "28px" }}
                          />
                          <MDTypography variant="h6">
                            &nbsp;&nbsp;Schedule created campaign start (Los-Angeles time)
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12} container alignItems="center">
                        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 5 }}>
                          <MDInput type="date" value={date} onChange={handleDateChange} />
                          <MDInput type="time" value={time} onChange={handleTimeChange} />
                          <Button
                            onClick={() => {
                              setDate("");
                              setTime("");
                            }}
                            variant="contained"
                            color="white"
                          >
                            🚫 SKIP
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/219650/leaves-plant.svg"
                            alt="Image"
                            style={{ width: "30px", height: "30px" }}
                          />
                          <MDTypography variant="h6">
                            &nbsp;&nbsp;FB link and GoogleDrive settings
                          </MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputField
                          inputValue={inputRFolderNameGD}
                          setInputValue={setInputRFolderNameGD}
                          label={"GoogleDrive folder_name or link (https://)"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <CustomInputFieldMultiline
                          inputValue={inputLinkForFB}
                          setInputValue={setInputLinkForFB}
                          label={"fb__link_for_fb"}
                          multiline
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/219650/leaves-plant.svg"
                            alt="Image"
                            style={{ width: "28px", height: "28px" }}
                          />
                          <MDTypography variant="h6">&nbsp;&nbsp;Ad Set settings</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Default: 1Adset/All_Ads
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputAdsPerAdset}
                          setInputValue={setInputAdsPerAdset}
                          label={"Ads per adset"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Required for ABO ⚠️ (Default: $5)
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputBudget}
                          setInputValue={setInputBudget}
                          label={"Daily budget (in $)"}
                          required={false}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="caption" color="black" sx={{ mb: 1 }}>
                          Required for BID/COST_CAP ⚠️ (Default: $1)
                        </Typography>
                        <CustomNumericInputField
                          inputValue={inputBidAmount}
                          setInputValue={setInputBidAmount}
                          label={"Bid/Cost amount (in $)"}
                          required={false}
                          allowFloat={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <CustomDropdown
                          selectedCategory={selectedStatus}
                          setSelectedCategory={setSelectedStatus}
                          categories={statusesAll}
                          category_name={"Adset status"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <MDBox mt={3} display="flex" alignItems="center">
                          <img
                            src="https://www.svgrepo.com/show/219650/leaves-plant.svg"
                            alt="Image"
                            style={{ width: "28px", height: "28px" }}
                          />
                          <MDTypography variant="h6">&nbsp;&nbsp;Ad settings</MDTypography>
                        </MDBox>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <CustomInputField
                          inputValue={inputAdjAdText}
                          setInputValue={setInputAdjAdText}
                          label={"Headline"}
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <CustomInputFieldMultiline
                          inputValue={inputDescription}
                          setInputValue={setInputDescription}
                          label={"Ad Description"}
                          multiline
                          required={true}
                        />
                      </Grid>
                      <Grid item xs={12} md={12} container alignItems="center">
                        <Grid item xs={11}>
                          <CustomInputFieldMultiline
                            inputValue={inputAdjAdCopies[0]}
                            setInputValue={(value) => handleInputChange(0, value)}
                            label={"Ad text"}
                            multiline
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton onClick={() => addInputField(0)}>
                            <AddIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                      {/* Поля для дополнительных текстов */}
                      {inputAdjAdCopies.slice(1).map((inputValue, index) => (
                        <Grid item xs={12} md={12} key={index + 1} container alignItems="center">
                          <Grid item xs={11}>
                            <CustomInputFieldMultiline
                              inputValue={inputValue}
                              setInputValue={(value) => handleInputChange(index + 1, value)} // Индекс увеличен на 1
                              label={`Ad text ${index + 2}`} // Динамическое имя поля
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton onClick={() => addInputField(index + 1)}>
                              <AddIcon />
                            </IconButton>
                            <IconButton onClick={() => removeInputField(index + 1)}>
                              {" "}
                              {/* Передаем текущий индекс + 1 */}
                              <ClearIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </MDBox>
                </Grid>
              </MDBox>
              <MDBox pt={2} px={2}>
                <ButtonList />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Card className="card-container">
              <MDBox p={2}>
                <MDTypography variant="h5">Response</MDTypography>
              </MDBox>
              <MDBox pt={2} px={2} sx={{ overflow: "auto" }}>
                <ResponseMessage />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UpdateCampaign;
