import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "../../../examples/Tables/DataTable";
import "react-toastify/dist/ReactToastify.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MDTypography from "../../../components/MDTypography";

function LogsActivityAutorules() {
  const Tips = [
    "This table shows the start and end time of each auto-rule execution.",
    "You can view which accounts and campaigns were not analyzed by the rule.",
    "It displays which accounts and campaigns were successfully checked by the rule.",
  ];

  const renderTips = (tipsList) => {
    return tipsList.map((item, key) => {
      const itemKey = `element-${key}`;

      return (
        <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
            {item}
          </MDTypography>
        </MDBox>
      );
    });
  };

  const [tableData, setTableData] = useState({
    columns: [
      {
        Header: "rule_id",
        accessor: "rule_id",
        width: "10%",
      },
      {
        Header: "func_name",
        accessor: "func_name",
        width: "20%",
      },
      {
        Header: "time_start",
        accessor: "time_start",
        width: "10%",
      },
      {
        Header: "is_activated",
        accessor: "is_activated",
        height: "50px",
        width: "5%",
        Cell: ({ value }) => renderNewValue(value),
      },
      {
        Header: "time_end",
        accessor: "time_end",
        width: "10%",
      },
      {
        Header: "is_finished",
        accessor: "is_finished",
        height: "50px",
        width: "5%",
        Cell: ({ value }) => renderNewValue(value),
      },
      {
        Header: "active campaigns",
        accessor: "active_campaigns",
        width: "10%",
        height: "100px",
      },
      {
        Header: <span style={{ paddingRight: "65px" }}>active accounts</span>,
        accessor: "active_accounts",
        width: "10%",
        height: "100px",
      },
      {
        Header: "paused campaigns",
        accessor: "paused_campaigns",
        width: "10%",
        height: "100px",
      },
      {
        Header: <span style={{ paddingRight: "65px" }}>paused accounts</span>,
        accessor: "paused_accounts",
        width: "10%",
        height: "100px",
      },
    ],
    rows: [],
  });

  const renderNewValue = (value) => (
    <span
      style={{
        color: value === "True" ? "rgb(0,111,255)" : "rgb(255,0,0)", // Синий для True, красный для False
        fontWeight: "bold",
      }}
    >
      {value.toString()}
    </span>
  );

  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://fb-campaign-creator-tmgx45bhsa-uc.a.run.app/logs_activity_auto_rules/"
        );
        const rows = response.data.map((row) => ({
          rule_id: row.rule_id,
          func_name: row.func_name,
          time_start: row.time_start,
          is_activated: row.is_activated,
          time_end: row.time_end,
          is_finished: row.is_finished,
          paused_campaigns: row.paused_campaigns,
          paused_accounts: row.paused_accounts,
          active_campaigns: row.active_campaigns,
          active_accounts: row.active_accounts,
        }));
        setTableData((prevState) => ({ ...prevState, rows: rows }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const filteredData = tableData.rows.filter((row) =>
    Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} mt={6}>
        <MDTypography variant="h5">Logs auto-rules</MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
          {renderTips(Tips)}
        </MDBox>
      </MDBox>
      <MDBox mt={6} mb={3}>
        <DataTable
          table={{
            ...tableData,
            rows: filteredData,
          }}
          canSearch
          searchValue={search}
          onSearchChange={setSearch}
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default LogsActivityAutorules;
