import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";

const DialogFormStopLoss2 = ({
  openEditDialog,
  handleEditDialogClose,
  handleSave,
  selectedRow,
  userRole,
  userName,
  handleValueChange,
  actionOptions,
  userValue1,
  userValue2,
  userValue3,
  userAction,
  userActionValue,
  tempValue1,
  tempValue2,
  tempValue3,
  handleActionChange,
  tempAction,
  tempActionValue,
  selectedAction,
  Tips,
}) => {
  return (
    <Dialog
      open={openEditDialog}
      onClose={handleEditDialogClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          width: "80%",
          height: "70%",
        },
      }}
    >
      <DialogTitle>Edit Rule</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2} padding={2}>
          <pre>
            <b>
              {selectedRow ? (
                <>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {selectedRow.condition1 && (
                      <>
                        {selectedRow.condition1}
                        <TextField
                          value={userRole === "admin" ? tempValue1 : userValue1[userName]}
                          onChange={(e) => handleValueChange("setValue1", e.target.value)}
                          variant="standard"
                          size="small"
                          type="number"
                          style={{ width: "6%" }}
                        />
                      </>
                    )}
                    {selectedRow.condition1_1 && (
                      <>
                        {selectedRow.condition1_1}
                        <TextField
                          value={userRole === "admin" ? tempValue2 : userValue2[userName]}
                          onChange={(e) => handleValueChange("setValue2", e.target.value)}
                          variant="standard"
                          size="small"
                          style={{ width: "6%" }}
                        />
                      </>
                    )}
                    {selectedRow.condition1_1 !== "" ? "% " : "$ "}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {selectedRow.condition1_2 && (
                      <>
                        {selectedRow.condition1_2}
                        {selectedRow.value3 && (
                          <>
                            <TextField
                              value={userRole === "admin" ? tempValue3 : userValue3[userName]}
                              onChange={(e) => handleValueChange("setValue3", e.target.value)}
                              variant="standard"
                              size="small"
                              style={{ width: "6%" }}
                            />
                            {tempValue3 !== "" ? "$" : ""}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <br />

                  {selectedRow.action && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src="https://www.svgrepo.com/show/199577/right-arrow-next.svg"
                        alt="Red Arrow"
                        style={{ width: "24px", height: "24px", marginRight: "20px" }}
                      />
                      <MUISelect
                        value={selectedAction}
                        onChange={handleActionChange}
                        variant="standard"
                      >
                        {actionOptions.map((option, index) => (
                          <MenuItem key={`${option}-${index}`} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </MUISelect>
                      &nbsp;&nbsp;
                      {selectedAction !== "только отправить уведомление" &&
                        selectedAction !== "остановить кампанию" &&
                        selectedAction !== "остановить адсет" &&
                        selectedAction !== "понизить бюджет до уровня spend" && (
                          <>
                            <TextField
                              value={
                                userRole === "admin" ? tempActionValue : userActionValue[userName]
                              }
                              onChange={(e) => handleValueChange("setActionValue", e.target.value)}
                              variant="standard"
                              size="small"
                              type="number"
                              style={{ width: "6%" }}
                            />
                            {tempActionValue !== "" ? "%" : ""}
                          </>
                        )}
                    </div>
                  )}
                </>
              ) : (
                <div>No data available</div>
              )}
            </b>
          </pre>
          <MDBox mb={1} mt={4}>
            <MDTypography variant="body3" color="text">
              Follow these tips to ensure correct ROI/Budget/Spend setup
            </MDTypography>
          </MDBox>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
              {Tips}
            </MDBox>
          </MDBox>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogFormStopLoss2;

DialogFormStopLoss2.propTypes = {
  openEditDialog: PropTypes.any,
  handleEditDialogClose: PropTypes.any,
  handleSave: PropTypes.any,
  selectedRow: PropTypes.any,
  userRole: PropTypes.any,
  userName: PropTypes.any,
  handleValueChange: PropTypes.any,
  actionOptions: PropTypes.any,
  userValue1: PropTypes.any,
  userValue2: PropTypes.any,
  userValue3: PropTypes.any,
  userAction: PropTypes.any,
  userActionValue: PropTypes.any,
  tempValue1: PropTypes.any,
  tempValue2: PropTypes.any,
  tempValue3: PropTypes.any,
  tempAction: PropTypes.any,
  tempActionValue: PropTypes.any,
  handleActionChange: PropTypes.any,
  selectedAction: PropTypes.any,
  Tips: PropTypes.any,
};
