import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  FormHelperText,
} from "@mui/material";
import PropTypes from "prop-types";

const CustomDropdown = ({
  selectedCategory,
  setSelectedCategory,
  categories,
  category_name,
  required,
  onCategoryChange,
}) => {
  const [touched, setTouched] = React.useState(false);

  useEffect(() => {
    if (required) {
      setTouched(true);
    }
  }, [required]);

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mb={2}
      sx={{
        gap: 2,
        width: "100%",
      }}
    >
      <FormControl
        variant="standard"
        sx={{ width: "100%" }}
        required={required}
        error={required && touched && !selectedCategory}
      >
        <Autocomplete
          value={selectedCategory}
          onChange={(event, newValue) => {
            setSelectedCategory(newValue);
            if (onCategoryChange) {
              onCategoryChange(newValue); // Вызываем сброс списка
            }
          }}
          onBlur={handleBlur}
          options={categories}
          renderInput={(params) => (
            <TextField
              {...params}
              label={category_name}
              variant="standard"
              required={required}
              error={required && touched && !selectedCategory}
              helperText={
                required && touched && !selectedCategory
                  ? `Please select a ${category_name.toLowerCase()}`
                  : ""
              }
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

CustomDropdown.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  category_name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onCategoryChange: PropTypes.func,
};

export default CustomDropdown;
