import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import MDBox from "../../../components/MDBox";
import DataTable from "examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "@mui/material/Card";
import AccountCheckboxList from "./data_accounts";
import CampaignCheckboxList from "./data_campaigns";
import { Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import MDTypography from "../../../components/MDTypography";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import DialogForm from "./stop_loss_dialog";
import { useFilteredData, useTableDataAdsetLogs } from "./adsets_logs";

function StopLoss() {
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Rule Name", accessor: "rule_name", disableSortBy: true, width: "30%" },
      { Header: "Description", accessor: "description", disableSortBy: true, width: "65%" },
      { Header: "EDIT", accessor: "edit", disableSortBy: true, width: "5%" },
    ],
    rows: [],
  });

  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const [tempROI_A, setTempROI_A] = useState("");
  const [tempROI_A_VLAD, setTempROI_A_VLAD] = useState("");
  const [tempROI_A_ARTEM, setTempROI_A_ARTEM] = useState("");
  const [tempROI_A_ALEX, setTempROI_A_ALEX] = useState("");

  const [tempROI_F_1, setTempROI_F_1] = useState("");
  const [tempROI_F_1_VLAD, setTempROI_F_1_VLAD] = useState("");
  const [tempROI_F_1_ARTEM, setTempROI_F_1_ARTEM] = useState("");
  const [tempROI_F_1_ALEX, setTempROI_F_1_ALEX] = useState("");

  const [tempROI_F_2, setTempROI_F_2] = useState("");
  const [tempROI_F_2_VLAD, setTempROI_F_2_VLAD] = useState("");
  const [tempROI_F_2_ARTEM, setTempROI_F_2_ARTEM] = useState("");
  const [tempROI_F_2_ALEX, setTempROI_F_2_ALEX] = useState("");

  const [tempBudget, setTempBudget] = useState("");
  const [tempBudget_VLAD, setTempBudget_VLAD] = useState("");
  const [tempBudget_ARTEM, setTempBudget_ARTEM] = useState("");
  const [tempBudget_ALEX, setTempBudget_ALEX] = useState("");

  const [tempSpend, setTempSpend] = useState("");
  const [tempSpend_VLAD, setTempSpend_VLAD] = useState("");
  const [tempSpend_ARTEM, setTempSpend_ARTEM] = useState("");
  const [tempSpend_ALEX, setTempSpend_ALEX] = useState("");

  const [tempROI_a_activate_campaign, setTempROI_a_activate_campaign] = useState("");
  const [tempROI_a_activate_campaign_VLAD, setTempROI_a_activate_campaign_VLAD] = useState("");
  const [tempROI_a_activate_campaign_ARTEM, setTempROI_a_activate_campaign_ARTEM] = useState("");
  const [tempROI_a_activate_campaign_ALEX, setTempROI_a_activate_campaign_ALEX] = useState("");

  const [tempROI_a_activate_adset, setTempROI_a_activate_adset] = useState("");
  const [tempROI_a_activate_adset_VLAD, setTempROI_a_activate_adset_VLAD] = useState("");
  const [tempROI_a_activate_adset_ARTEM, setTempROI_a_activate_adset_ARTEM] = useState("");
  const [tempROI_a_activate_adset_ALEX, setTempROI_a_activate_adset_ALEX] = useState("");

  const [tempL_1_budget, setTempL_1_budget] = useState("");
  const [tempL_1_budget_VLAD, setTempL_1_budget_VLAD] = useState("");
  const [tempL_1_budget_ARTEM, setTempL_1_budget_ARTEM] = useState("");
  const [tempL_1_budget_ALEX, setTempL_1_budget_ALEX] = useState("");

  const [tempROI_a_5_days, setTempROI_a_5_days] = useState("");
  const [tempROI_a_5_days_VLAD, setTempROI_a_5_days_VLAD] = useState("");
  const [tempROI_a_5_days_ARTEM, setTempROI_a_5_days_ARTEM] = useState("");
  const [tempROI_a_5_days_ALEX, setTempROI_a_5_days_ALEX] = useState("");

  const [tempL_1_spend, setTempL_1_spend] = useState("");
  const [tempL_1_spend_VLAD, setTempL_1_spend_VLAD] = useState("");
  const [tempL_1_spend_ARTEM, setTempL_1_spend_ARTEM] = useState("");
  const [tempL_1_spend_ALEX, setTempL_1_spend_ALEX] = useState("");

  const [tempABO_adset_spend_percent, setTempABO_adset_spend_percent] = useState("");
  const [tempABO_adset_spend_percent_VLAD, setTempABO_adset_spend_percent_VLAD] = useState("");
  const [tempABO_adset_spend_percent_ARTEM, setTempABO_adset_spend_percent_ARTEM] = useState("");
  const [tempABO_adset_spend_percent_ALEX, setTempABO_adset_spend_percent_ALEX] = useState("");

  const [tempABO_adset_roi_f, setTempABO_adset_roi_f] = useState("");
  const [tempABO_adset_roi_f_VLAD, setTempABO_adset_roi_f_VLAD] = useState("");
  const [tempABO_adset_roi_f_ARTEM, setTempABO_adset_roi_f_ARTEM] = useState("");
  const [tempABO_adset_roi_f_ALEX, setTempABO_adset_roi_f_ALEX] = useState("");

  const tableDataAdsetLogs = useTableDataAdsetLogs("stop_loss");
  const filteredDataLogs = useFilteredData(tableDataAdsetLogs, search);

  const API_URL = "https://dashboard-1049948018753.us-central1.run.app";

  const userEmail = localStorage.getItem("userEmail");
  const userRole = localStorage.getItem("userRole");

  const userNames = {
    "Artemyusi@gmail.com": "ARTEM",
    "Vlad0986613794@gmail.com": "VLAD",
    "nuzhnyias@gmail.com": "ALEX",
  };

  const budgetROITips = [
    "To use default values, clear your inputs and submit empty fields.",
    "The symbols < and > exclude the number they compare to, so the boundary is not included.",
    "The symbols <= and >= include the number they compare to, so the boundary is included.",
    "For negative values, specify the dash symbol without spaces(ex. -25).",
  ];
  const ruleTips = [
    "The rule does not apply between 10 AM and 2 PM.",
    "To disable a rule, turn it off for all accounts or use the toggle.",
    "The rule will not work on campaigns from accounts that are turned off.",
    "Daily budget for ABO campaigns it is the total budget of every adset",
    "Campaigns that include matches from the exclusion list will be automatically excluded (TEST_CAMPAIGN, Engagement campaign, COST_CAP, COSTCAP, BID_CAP, BIDCAP, GC-UTC-305, GC-UTC-304, - L0, (S1_)",
  ];

  const renderTips = (tipsList) => {
    return tipsList.map((item, key) => {
      const itemKey = `element-${key}`;

      return (
        <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
          <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
            {item}
          </MDTypography>
        </MDBox>
      );
    });
  };

  const userName = userNames[userEmail];

  useEffect(() => {
    fetchData();
  }, []);

  const generateDescription = (rule, userRole, userName) => {
    if (userRole === "admin") {
      return (
        <pre>
          ОПИСАНИЕ:
          <br />
          1. {rule.condition1}
          <br />
          1.1 {rule.condition1_1}
          <br />
          <br />
          2. {rule.condition2} {rule.ROI_a_value}%
          <br />
          2.1 {rule.condition2_1} {rule.ROI_a_activate_campaign}%
          <br />
          2.2 {rule.condition2_2} {rule.ROI_a_activate_campaign}%
          <br />
          2.3 {rule.condition2_3} {rule.ROI_a_activate_adset}%
          <br />
          2.4 {rule.condition2_4} {rule.ROI_a_5_days}%
          <br />
          <br />
          3. {rule.condition1_2} {rule.L_1_budget}$
          <br />
          <br />
          УСЛОВИЕ:
          <br />
          1. {rule.condition3} &gt;= {rule.daily_budget_value}% И {rule.condition4} &lt;={" "}
          {rule.ROI_f_max}%
          <br />
          2. {rule.condition3_1} {rule.ABO_adset_spend_percent}% {rule.condition3_2}{" "}
          {rule.ABO_adset_roi_f}%
          <br />
          3. {rule.condition5} &gt;= {rule.Spend_value}$ И {rule.condition6} &lt;= {rule.ROI_f_min}%
          <br />
          4. {rule.condition4_1} {rule.L_1_spend}$
        </pre>
      );
    } else {
      return (
        <pre>
          ОПИСАНИЕ:
          <br />
          1. {rule.condition1}
          <br />
          1.1 {rule.condition1_1}
          <br />
          <br />
          2. {rule.condition2} {rule[`ROI_a_value_${userName}`]}%
          <br />
          2.1 {rule.condition2_1} {rule[`ROI_a_activate_campaign_${userName}`]}%
          <br />
          2.2 {rule.condition2_2} {rule[`ROI_a_activate_campaign_${userName}`]}%
          <br />
          2.3 {rule.condition2_3} {rule[`ROI_a_activate_adset_${userName}`]}%
          <br />
          2.4 {rule.condition2_4} {rule[`ROI_a_5_days_${userName}`]}%
          <br />
          <br />
          3. {rule.condition1_2} {rule[`L_1_budget_${userName}`]}$
          <br />
          <br />
          УСЛОВИЕ:
          <br />
          1. {rule.condition3} &gt;= {rule[`daily_budget_value_${userName}`]}% И {rule.condition4}{" "}
          &lt;= {rule[`ROI_f_max_${userName}`]}%
          <br />
          2. {rule.condition3_1} {rule[`ABO_adset_spend_percent_${userName}`]}% {rule.condition3_2}{" "}
          {rule[`ABO_adset_roi_f_${userName}`]}%
          <br />
          3. {rule.condition5} &gt;= {rule[`Spend_value_${userName}`]}$ И {rule.condition6} &lt;={" "}
          {rule[`ROI_f_min_${userName}`]}%
          <br />
          4. {rule.condition4_1} {rule[`L_1_spend_${userName}`]}$
        </pre>
      );
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.post(`${API_URL}/api/rules/`, {
        rule_names: ["stop_loss_1"],
      });

      const rows = response.data.map((rule) => {
        // Определяем содержимое описания в зависимости от роли пользователя
        const descriptionContent = generateDescription(rule, userRole, userName);

        return {
          rule_id: rule.rule_id,
          rule_name: (
            <div>
              {/* Глобальный статус */}
              <FormControlLabel
                control={
                  <Switch
                    checked={rule.status}
                    onChange={() => handleToggle(rule.rule_id, "status", rule.status)}
                    disabled={userRole !== "admin"}
                  />
                }
                label={`${rule.rule_name} (${rule.status ? "Active" : "Paused"})`}
              />

              {/* Статусы пользователей */}
              {userRole === "admin" && (
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={rule.status_VLAD}
                        onChange={() => handleToggle(rule.rule_id, "status_VLAD", rule.status_VLAD)}
                      />
                    }
                    label={`VLAD (${rule.status_VLAD ? "Active" : "Paused"})`}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={rule.status_ARTEM}
                        onChange={() =>
                          handleToggle(rule.rule_id, "status_ARTEM", rule.status_ARTEM)
                        }
                      />
                    }
                    label={`ARTEM (${rule.status_ARTEM ? "Active" : "Paused"})`}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={rule.status_ALEX}
                        onChange={() => handleToggle(rule.rule_id, "status_ALEX", rule.status_ALEX)}
                      />
                    }
                    label={`ALEX (${rule.status_ALEX ? "Active" : "Paused"})`}
                  />
                </div>
              )}

              {/* Статус для обычных пользователей */}
              {userRole !== "admin" && userNames[userEmail] && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={rule[`status_${userNames[userEmail]}`]}
                      onChange={() =>
                        handleToggle(
                          rule.rule_id,
                          `status_${userNames[userEmail]}`,
                          rule[`status_${userNames[userEmail]}`]
                        )
                      }
                    />
                  }
                  label={`${userNames[userEmail]} (${
                    rule[`status_${userNames[userEmail]}`] ? "Active" : "Paused"
                  })`}
                />
              )}
            </div>
          ),
          status: rule.status,
          status_VLAD: rule.status_VLAD,
          status_ARTEM: rule.status_ARTEM,
          status_ALEX: rule.status_ALEX,
          created_time: rule.created_time,
          exception1: rule.exception1,
          exception_value: rule.exception_value,
          condition1: rule.condition1,
          condition1_2: rule.condition1_2,
          condition1_1: rule.condition1_1,
          condition2: rule.condition2,
          condition2_1: rule.condition2_1,
          condition2_2: rule.condition2_2,
          condition2_3: rule.condition2_3,
          condition2_4: rule.condition2_4,
          condition3: rule.condition3,
          condition3_1: rule.condition3_1,
          condition3_2: rule.condition3_2,
          condition4: rule.condition4,
          condition4_1: rule.condition4_1,
          condition5: rule.condition5,
          condition6: rule.condition6,
          Spend_value: rule.Spend_value,
          ROI_a_value: rule.ROI_a_value,
          ROI_a_activate_campaign: rule.ROI_a_activate_campaign,
          ROI_a_activate_adset: rule.ROI_a_activate_adset,
          ROI_a_5_days: rule.ROI_a_5_days,
          L_1_budget: rule.L_1_budget,
          L_1_spend: rule.L_1_spend,
          ABO_adset_spend_percent: rule.ABO_adset_spend_percent,
          ABO_adset_roi_f: rule.ABO_adset_roi_f,
          daily_budget_value: rule.daily_budget_value,
          Spend_value_VLAD: rule.Spend_value_VLAD,
          ROI_a_value_VLAD: rule.ROI_a_value_VLAD,
          daily_budget_value_VLAD: rule.daily_budget_value_VLAD,
          ROI_a_5_days_VLAD: rule.ROI_a_5_days_VLAD,
          ROI_a_activate_campaign_VLAD: rule.ROI_a_activate_campaign_VLAD,
          ROI_a_activate_adset_VLAD: rule.ROI_a_activate_adset_VLAD,
          L_1_budget_VLAD: rule.L_1_budget_VLAD,
          L_1_spend_VLAD: rule.L_1_spend_VLAD,
          ABO_adset_spend_percent_VLAD: rule.ABO_adset_spend_percent_VLAD,
          ABO_adset_roi_f_VLAD: rule.ABO_adset_roi_f_VLAD,
          Spend_value_ARTEM: rule.Spend_value_ARTEM,
          ROI_a_value_ARTEM: rule.ROI_a_value_ARTEM,
          daily_budget_value_ARTEM: rule.daily_budget_value_ARTEM,
          ROI_a_5_days_ARTEM: rule.ROI_a_5_days_ARTEM,
          Spend_value_ALEX: rule.Spend_value_ALEX,
          ROI_a_value_ALEX: rule.ROI_a_value_ALEX,
          daily_budget_value_ALEX: rule.daily_budget_value_ALEX,
          ROI_a_5_days_ALEX: rule.ROI_a_5_days_ALEX,
          ROI_a_activate_campaign_ARTEM: rule.ROI_a_activate_campaign_ARTEM,
          ROI_a_activate_campaign_ALEX: rule.ROI_a_activate_campaign_ALEX,
          ROI_a_activate_adset_ARTEM: rule.ROI_a_activate_adset_ARTEM,
          ROI_a_activate_adset_ALEX: rule.ROI_a_activate_adset_ALEX,
          L_1_budget_ARTEM: rule.L_1_budget_ARTEM,
          L_1_budget_ALEX: rule.L_1_budget_ALEX,
          L_1_spend_ARTEM: rule.L_1_spend_ARTEM,
          L_1_spend_ALEX: rule.L_1_spend_ALEX,
          ABO_adset_spend_percent_ARTEM: rule.ABO_adset_spend_percent_ARTEM,
          ABO_adset_spend_percent_ALEX: rule.ABO_adset_spend_percent_ALEX,
          ABO_adset_roi_f_ARTEM: rule.ABO_adset_roi_f_ARTEM,
          ABO_adset_roi_f_ALEX: rule.ABO_adset_roi_f_ALEX,
          description: descriptionContent,
          edit: (
            <IconButton onClick={() => handleEditClick(rule)} aria-label="edit" size="large">
              <EditIcon />
            </IconButton>
          ),
        };
      });

      setTableData((prevState) => ({ ...prevState, rows }));
      setFilteredData(rows);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setFilteredData(
      tableData.rows.filter((row) =>
        Object.values(row).some((val) => String(val).toLowerCase().includes(search.toLowerCase()))
      )
    );
  }, [search, tableData.rows]);

  const handleToggle = useCallback(async (ruleId = "", columnName, currentStatus) => {
    const newStatus = currentStatus === true ? "Inactive" : "Active";
    console.log("Current status/new status:", currentStatus, newStatus);

    // Optimistically update UI
    setTableData((prevState) => ({
      ...prevState,
      rows: prevState.rows.map((row) =>
        row.rule_id === ruleId
          ? {
              ...row,
              [columnName]: newStatus,
              toggleSwitch: (
                <FormControlLabel
                  key={ruleId} // Ensure a unique key
                  control={
                    <Switch
                      checked={newStatus === "Active"}
                      onChange={() => handleToggle(ruleId, columnName, newStatus)}
                    />
                  }
                  label=""
                />
              ),
            }
          : row
      ),
    }));

    // Send request to update rule status
    const url = `${API_URL}/api/rules/update_status/`;

    try {
      console.log("ruleId/columnName/newStatus:", ruleId, columnName, newStatus);
      await axios.post(url, {
        rule_id: ruleId,
        column_name: columnName,
        status: newStatus,
        user_email: userEmail,
      });
      toast.success(`Status for ${columnName} updated to ${newStatus}!`);
      await fetchData();
    } catch (error) {
      // Rollback UI state if update fails
      setTableData((prevState) => ({
        ...prevState,
        rows: prevState.rows.map((row) =>
          row.rule_id === ruleId ? { ...row, [columnName]: currentStatus } : row
        ),
      }));
      toast.error(`Failed to update rule status for ID ${ruleId}.`);
    }
  }, []);

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditDialog(true);

    setTempROI_A(row.ROI_a_value);
    setTempROI_A_VLAD(row.ROI_a_value_VLAD);
    setTempROI_A_ARTEM(row.ROI_a_value_ARTEM);
    setTempROI_A_ALEX(row.ROI_a_value_ALEX);

    setTempROI_F_1(row.ROI_f_max);
    setTempROI_F_1_VLAD(row.ROI_f_max_VLAD);
    setTempROI_F_1_ARTEM(row.ROI_f_max_ARTEM);
    setTempROI_F_1_ALEX(row.ROI_f_max_ALEX);

    setTempROI_F_2(row.ROI_f_min);
    setTempROI_F_2_VLAD(row.ROI_f_min_VLAD);
    setTempROI_F_2_ARTEM(row.ROI_f_min_ARTEM);
    setTempROI_F_2_ALEX(row.ROI_f_min_ALEX);

    setTempBudget(row.daily_budget_value);
    setTempBudget_VLAD(row.daily_budget_value_VLAD);
    setTempBudget_ARTEM(row.daily_budget_value_ARTEM);
    setTempBudget_ALEX(row.daily_budget_value_ALEX);

    setTempSpend(row.Spend_value);
    setTempSpend_VLAD(row.Spend_value_VLAD);
    setTempSpend_ARTEM(row.Spend_value_ARTEM);
    setTempSpend_ALEX(row.Spend_value_ALEX);

    setTempROI_a_activate_campaign(row.ROI_a_activate_campaign);
    setTempROI_a_activate_campaign_VLAD(row.ROI_a_activate_campaign_VLAD);
    setTempROI_a_activate_campaign_ARTEM(row.ROI_a_activate_campaign_ARTEM);
    setTempROI_a_activate_campaign_ALEX(row.ROI_a_activate_campaign_ALEX);

    setTempROI_a_activate_adset(row.ROI_a_activate_adset);
    setTempROI_a_activate_adset_VLAD(row.ROI_a_activate_adset_VLAD);
    setTempROI_a_activate_adset_ARTEM(row.ROI_a_activate_adset_ARTEM);
    setTempROI_a_activate_adset_ALEX(row.ROI_a_activate_adset_ALEX);

    setTempL_1_budget(row.L_1_budget);
    setTempL_1_budget_VLAD(row.L_1_budget_VLAD);
    setTempL_1_budget_ARTEM(row.L_1_budget_ARTEM);
    setTempL_1_budget_ALEX(row.L_1_budget_ALEX);

    setTempROI_a_5_days(row.ROI_a_5_days);
    setTempROI_a_5_days_VLAD(row.ROI_a_5_days_VLAD);
    setTempROI_a_5_days_ARTEM(row.ROI_a_5_days_ARTEM);
    setTempROI_a_5_days_ALEX(row.ROI_a_5_days_ALEX);

    setTempL_1_spend(row.L_1_spend);
    setTempL_1_spend_VLAD(row.L_1_spend_VLAD);
    setTempL_1_spend_ARTEM(row.L_1_spend_ARTEM);
    setTempL_1_spend_ALEX(row.L_1_spend_ALEX);

    setTempABO_adset_spend_percent(row.ABO_adset_spend_percent);
    setTempABO_adset_spend_percent_VLAD(row.ABO_adset_spend_percent_VLAD);
    setTempABO_adset_spend_percent_ARTEM(row.ABO_adset_spend_percent_ARTEM);
    setTempABO_adset_spend_percent_ALEX(row.ABO_adset_spend_percent_ALEX);

    setTempABO_adset_roi_f(row.ABO_adset_roi_f);
    setTempABO_adset_roi_f_VLAD(row.ABO_adset_roi_f_VLAD);
    setTempABO_adset_roi_f_ARTEM(row.ABO_adset_roi_f_ARTEM);
    setTempABO_adset_roi_f_ALEX(row.ABO_adset_roi_f_ALEX);
  };

  const handleEditDialogClose = () => {
    setOpenEditDialog(false);
    setSelectedRow(null);

    setTempROI_A("");
    setTempROI_A_VLAD("");
    setTempROI_A_ARTEM("");
    setTempROI_A_ALEX("");

    setTempROI_F_1("");
    setTempROI_F_1_VLAD("");
    setTempROI_F_1_ARTEM("");
    setTempROI_F_1_ALEX("");

    setTempROI_F_2("");
    setTempROI_F_2_VLAD("");
    setTempROI_F_2_ARTEM("");
    setTempROI_F_2_ALEX("");

    setTempBudget("");
    setTempBudget_VLAD("");
    setTempBudget_ARTEM("");
    setTempBudget_ALEX("");

    setTempSpend("");
    setTempSpend_VLAD("");
    setTempSpend_ARTEM("");
    setTempSpend_ALEX("");

    setTempROI_a_activate_campaign("");
    setTempROI_a_activate_campaign_VLAD("");
    setTempROI_a_activate_campaign_ARTEM("");
    setTempROI_a_activate_campaign_ALEX("");

    setTempROI_a_activate_adset("");
    setTempROI_a_activate_adset_VLAD("");
    setTempROI_a_activate_adset_ARTEM("");
    setTempROI_a_activate_adset_ALEX("");

    setTempL_1_budget("");
    setTempL_1_budget_VLAD("");
    setTempL_1_budget_ARTEM("");
    setTempL_1_budget_ALEX("");

    setTempROI_a_5_days("");
    setTempROI_a_5_days_VLAD("");
    setTempROI_a_5_days_ARTEM("");
    setTempROI_a_5_days_ALEX("");

    setTempL_1_spend("");
    setTempL_1_spend_VLAD("");
    setTempL_1_spend_ARTEM("");
    setTempL_1_spend_ALEX("");

    setTempABO_adset_spend_percent("");
    setTempABO_adset_spend_percent_VLAD("");
    setTempABO_adset_spend_percent_ARTEM("");
    setTempABO_adset_spend_percent_ALEX("");

    setTempABO_adset_roi_f("");
    setTempABO_adset_roi_f_VLAD("");
    setTempABO_adset_roi_f_ARTEM("");
    setTempABO_adset_roi_f_ALEX("");
  };

  const handleSave = async () => {
    if (!selectedRow) return;
    const updatedData = {
      rule_id: selectedRow.rule_id,
      ROI_a_value: tempROI_A,
      ROI_a_value_VLAD: tempROI_A_VLAD,
      ROI_a_value_ARTEM: tempROI_A_ARTEM,
      ROI_a_value_ALEX: tempROI_A_ALEX,

      ROI_f_max: tempROI_F_1,
      ROI_f_max_VLAD: tempROI_F_1_VLAD,
      ROI_f_max_ARTEM: tempROI_F_1_ARTEM,
      ROI_f_max_ALEX: tempROI_F_1_ALEX,

      ROI_f_min: tempROI_F_2,
      ROI_f_min_VLAD: tempROI_F_2_VLAD,
      ROI_f_min_ARTEM: tempROI_F_2_ARTEM,
      ROI_f_min_ALEX: tempROI_F_2_ALEX,

      daily_budget_value: tempBudget,
      daily_budget_value_VLAD: tempBudget_VLAD,
      daily_budget_value_ARTEM: tempBudget_ARTEM,
      daily_budget_value_ALEX: tempBudget_ALEX,

      Spend_value: tempSpend,
      Spend_value_VLAD: tempSpend_VLAD,
      Spend_value_ARTEM: tempSpend_ARTEM,
      Spend_value_ALEX: tempSpend_ALEX,

      ROI_a_activate_campaign: tempROI_a_activate_campaign,
      ROI_a_activate_campaign_VLAD: tempROI_a_activate_campaign_VLAD,
      ROI_a_activate_campaign_ARTEM: tempROI_a_activate_campaign_ARTEM,
      ROI_a_activate_campaign_ALEX: tempROI_a_activate_campaign_ALEX,

      ROI_a_activate_adset: tempROI_a_activate_adset,
      ROI_a_activate_adset_VLAD: tempROI_a_activate_adset_VLAD,
      ROI_a_activate_adset_ARTEM: tempROI_a_activate_adset_ARTEM,
      ROI_a_activate_adset_ALEX: tempROI_a_activate_adset_ALEX,

      L_1_budget: tempL_1_budget,
      L_1_budget_VLAD: tempL_1_budget_VLAD,
      L_1_budget_ARTEM: tempL_1_budget_ARTEM,
      L_1_budget_ALEX: tempL_1_budget_ALEX,

      ROI_a_5_days: tempROI_a_5_days,
      ROI_a_5_days_VLAD: tempROI_a_5_days_VLAD,
      ROI_a_5_days_ARTEM: tempROI_a_5_days_ARTEM,
      ROI_a_5_days_ALEX: tempROI_a_5_days_ALEX,

      L_1_spend: tempL_1_spend,
      L_1_spend_VLAD: tempL_1_spend_VLAD,
      L_1_spend_ARTEM: tempL_1_spend_ARTEM,
      L_1_spend_ALEX: tempL_1_spend_ALEX,

      ABO_adset_spend_percent: tempABO_adset_spend_percent,
      ABO_adset_spend_percent_VLAD: tempABO_adset_spend_percent_VLAD,
      ABO_adset_spend_percent_ARTEM: tempABO_adset_spend_percent_ARTEM,
      ABO_adset_spend_percent_ALEX: tempABO_adset_spend_percent_ALEX,

      ABO_adset_roi_f: tempABO_adset_roi_f,
      ABO_adset_roi_f_VLAD: tempABO_adset_roi_f_VLAD,
      ABO_adset_roi_f_ARTEM: tempABO_adset_roi_f_ARTEM,
      ABO_adset_roi_f_ALEX: tempABO_adset_roi_f_ALEX,

      user_email: userEmail,
    };
    try {
      await axios.post(`${API_URL}/api/rules/update_rule_stop_loss_1/`, updatedData);

      const updatedRows = tableData.rows.map((row) =>
        row.rule_id === selectedRow.rule_id
          ? {
              ...row,
              ROI_a_value: tempROI_A,
              ROI_a_value_VLAD: tempROI_A_VLAD,
              ROI_a_value_ARTEM: tempROI_A_ARTEM,
              ROI_a_value_ALEX: tempROI_A_ALEX,

              ROI_f_max: tempROI_F_1,
              ROI_f_max_VLAD: tempROI_F_1_VLAD,
              ROI_f_max_ARTEM: tempROI_F_1_ARTEM,
              ROI_f_max_ALEX: tempROI_F_1_ALEX,

              ROI_f_min: tempROI_F_2,
              ROI_f_min_VLAD: tempROI_F_2_VLAD,
              ROI_f_min_ARTEM: tempROI_F_2_ARTEM,
              ROI_f_min_ALEX: tempROI_F_2_ALEX,

              daily_budget_value: tempBudget,
              daily_budget_value_VLAD: tempBudget_VLAD,
              daily_budget_value_ARTEM: tempBudget_ARTEM,
              daily_budget_value_ALEX: tempBudget_ALEX,

              Spend_value: tempSpend,
              Spend_value_VLAD: tempSpend_VLAD,
              Spend_value_ARTEM: tempSpend_ARTEM,
              Spend_value_ALEX: tempSpend_ALEX,

              ROI_a_activate_campaign: tempROI_a_activate_campaign,
              ROI_a_activate_campaign_VLAD: tempROI_a_activate_campaign_VLAD,
              ROI_a_activate_campaign_ARTEM: tempROI_a_activate_campaign_ARTEM,
              ROI_a_activate_campaign_ALEX: tempROI_a_activate_campaign_ALEX,

              ROI_a_activate_adset: tempROI_a_activate_adset,
              ROI_a_activate_adset_VLAD: tempROI_a_activate_adset_VLAD,
              ROI_a_activate_adset_ARTEM: tempROI_a_activate_adset_ARTEM,
              ROI_a_activate_adset_ALEX: tempROI_a_activate_adset_ALEX,

              L_1_budget: tempL_1_budget,
              L_1_budget_VLAD: tempL_1_budget_VLAD,
              L_1_budget_ARTEM: tempL_1_budget_ARTEM,
              L_1_budget_ALEX: tempL_1_budget_ALEX,

              ROI_a_5_days: tempROI_a_5_days,
              ROI_a_5_days_VLAD: tempROI_a_5_days_VLAD,
              ROI_a_5_days_ARTEM: tempROI_a_5_days_ARTEM,
              ROI_a_5_days_ALEX: tempROI_a_5_days_ALEX,

              L_1_spend: tempL_1_spend,
              L_1_spend_VLAD: tempL_1_spend_VLAD,
              L_1_spend_ARTEM: tempL_1_spend_ARTEM,
              L_1_spend_ALEX: tempL_1_spend_ALEX,

              ABO_adset_spend_percent: tempABO_adset_spend_percent,
              ABO_adset_spend_percent_VLAD: tempABO_adset_spend_percent_VLAD,
              ABO_adset_spend_percent_ARTEM: tempABO_adset_spend_percent_ARTEM,
              ABO_adset_spend_percent_ALEX: tempABO_adset_spend_percent_ALEX,

              ABO_adset_roi_f: tempABO_adset_roi_f,
              ABO_adset_roi_f_VLAD: tempABO_adset_roi_f_VLAD,
              ABO_adset_roi_f_ARTEM: tempABO_adset_roi_f_ARTEM,
              ABO_adset_roi_f_ALEX: tempABO_adset_roi_f_ALEX,
            }
          : row
      );

      // setTableData((prevState) => ({
      //   ...prevState,
      //   rows: updatedRows,
      // }));
      await fetchData();
      toast.success("Data updated successfully!");
      handleEditDialogClose(); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const userROI_A = {
    VLAD: tempROI_A_VLAD,
    ARTEM: tempROI_A_ARTEM,
    ALEX: tempROI_A_ALEX,
  };

  const userROI_F_1 = {
    VLAD: tempROI_F_1_VLAD,
    ARTEM: tempROI_F_1_ARTEM,
    ALEX: tempROI_F_1_ALEX,
  };

  const userROI_F_2 = {
    VLAD: tempROI_F_2_VLAD,
    ARTEM: tempROI_F_2_ARTEM,
    ALEX: tempROI_F_2_ALEX,
  };

  const userBudget = {
    VLAD: tempBudget_VLAD,
    ARTEM: tempBudget_ARTEM,
    ALEX: tempBudget_ALEX,
  };

  const userSpend = {
    VLAD: tempSpend_VLAD,
    ARTEM: tempSpend_ARTEM,
    ALEX: tempSpend_ALEX,
  };

  const userROI_A_activate_campaign = {
    VLAD: tempROI_a_activate_campaign_VLAD,
    ARTEM: tempROI_a_activate_campaign_ARTEM,
    ALEX: tempROI_a_activate_campaign_ALEX,
  };

  const userROI_A_activate_adset = {
    VLAD: tempROI_a_activate_adset_VLAD,
    ARTEM: tempROI_a_activate_adset_ARTEM,
    ALEX: tempROI_a_activate_adset_ALEX,
  };

  const userL_1_budget = {
    VLAD: tempL_1_budget_VLAD,
    ARTEM: tempL_1_budget_ARTEM,
    ALEX: tempL_1_budget_ALEX,
  };

  const userROI_a_5_days = {
    VLAD: tempROI_a_5_days_VLAD,
    ARTEM: tempROI_a_5_days_ARTEM,
    ALEX: tempROI_a_5_days_ALEX,
  };

  const userL_1_spend = {
    VLAD: tempL_1_spend_VLAD,
    ARTEM: tempL_1_spend_ARTEM,
    ALEX: tempL_1_spend_ALEX,
  };

  const userABO_adset_spend_percent = {
    VLAD: tempABO_adset_spend_percent_VLAD,
    ARTEM: tempABO_adset_spend_percent_ARTEM,
    ALEX: tempABO_adset_spend_percent_ALEX,
  };

  const userABO_adset_roi_f = {
    VLAD: tempABO_adset_roi_f_VLAD,
    ARTEM: tempABO_adset_roi_f_ARTEM,
    ALEX: tempABO_adset_roi_f_ALEX,
  };

  const setFunctions = {
    VLAD: {
      setTempROI_A: setTempROI_A_VLAD,
      setTempBudget: setTempBudget_VLAD,
      setTempROI_F_1: setTempROI_F_1_VLAD,
      setTempSpend: setTempSpend_VLAD,
      setTempROI_F_2: setTempROI_F_2_VLAD,

      setTempROI_a_activate_campaign: setTempROI_a_activate_campaign_VLAD,
      setTempROI_a_activate_adset: setTempROI_a_activate_adset_VLAD,
      setTempL_1_budget: setTempL_1_budget_VLAD,
      setTempROI_a_5_days: setTempROI_a_5_days_VLAD,
      setTempL_1_spend: setTempL_1_spend_VLAD,
      setTempABO_adset_spend_percent: setTempABO_adset_spend_percent_VLAD,
      setTempABO_adset_roi_f: setTempABO_adset_roi_f_VLAD,
    },
    ARTEM: {
      setTempROI_A: setTempROI_A_ARTEM,
      setTempBudget: setTempBudget_ARTEM,
      setTempROI_F_1: setTempROI_F_1_ARTEM,
      setTempSpend: setTempSpend_ARTEM,
      setTempROI_F_2: setTempROI_F_2_ARTEM,

      setTempROI_a_activate_campaign: setTempROI_a_activate_campaign_ARTEM,
      setTempROI_a_activate_adset: setTempROI_a_activate_adset_ARTEM,
      setTempL_1_budget: setTempL_1_budget_ARTEM,
      setTempROI_a_5_days: setTempROI_a_5_days_ARTEM,
      setTempL_1_spend: setTempL_1_spend_ARTEM,
      setTempABO_adset_spend_percent: setTempABO_adset_spend_percent_ARTEM,
      setTempABO_adset_roi_f: setTempABO_adset_roi_f_ARTEM,
    },
    ALEX: {
      setTempROI_A: setTempROI_A_ALEX,
      setTempBudget: setTempBudget_ALEX,
      setTempROI_F_1: setTempROI_F_1_ALEX,
      setTempSpend: setTempSpend_ALEX,
      setTempROI_F_2: setTempROI_F_2_ALEX,

      setTempROI_a_activate_campaign: setTempROI_a_activate_campaign_ALEX,
      setTempROI_a_activate_adset: setTempROI_a_activate_adset_ALEX,
      setTempL_1_budget: setTempL_1_budget_ALEX,
      setTempROI_a_5_days: setTempROI_a_5_days_ALEX,
      setTempL_1_spend: setTempL_1_spend_ALEX,
      setTempABO_adset_spend_percent: setTempABO_adset_spend_percent_ALEX,
      setTempABO_adset_roi_f: setTempABO_adset_roi_f_ALEX,
    },
  };

  const handleValueChange = (field, value) => {
    if (userRole === "admin") {
      switch (field) {
        case "ROI_A":
          setTempROI_A(value);
          break;
        case "Budget":
          setTempBudget(value);
          break;
        case "ROI_F_1":
          setTempROI_F_1(value);
          break;
        case "Spend":
          setTempSpend(value);
          break;
        case "ROI_F_2":
          setTempROI_F_2(value);
          break;
        case "ROI_a_activate_campaign":
          setTempROI_a_activate_campaign(value);
          break;
        case "ROI_a_activate_adset":
          setTempROI_a_activate_adset(value);
          break;
        case "L_1_budget":
          setTempL_1_budget(value);
          break;
        case "ROI_a_5_days":
          setTempROI_a_5_days(value);
          break;
        case "L_1_spend":
          setTempL_1_spend(value);
          break;
        case "ABO_adset_spend_percent":
          setTempABO_adset_spend_percent(value);
          break;
        case "ABO_adset_roi_f":
          setTempABO_adset_roi_f(value);
          break;
        default:
          break;
      }
    } else {
      const userSetFunctions = setFunctions[userName];
      if (userSetFunctions) {
        switch (field) {
          case "ROI_A":
            userSetFunctions.setTempROI_A(value);
            break;
          case "Budget":
            userSetFunctions.setTempBudget(value);
            break;
          case "ROI_F_1":
            userSetFunctions.setTempROI_F_1(value);
            break;
          case "Spend":
            userSetFunctions.setTempSpend(value);
            break;
          case "ROI_F_2":
            userSetFunctions.setTempROI_F_2(value);
            break;
          case "ROI_a_activate_campaign":
            userSetFunctions.setTempROI_a_activate_campaign(value);
            break;
          case "ROI_a_activate_adset":
            userSetFunctions.setTempROI_a_activate_adset(value);
            break;
          case "L_1_budget":
            userSetFunctions.setTempL_1_budget(value);
            break;
          case "ROI_a_5_days":
            userSetFunctions.setTempROI_a_5_days(value);
            break;
          case "L_1_spend":
            userSetFunctions.setTempL_1_spend(value);
            break;
          case "ABO_adset_spend_percent":
            userSetFunctions.setTempABO_adset_spend_percent(value);
            break;
          case "ABO_adset_roi_f":
            userSetFunctions.setTempABO_adset_roi_f(value);
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mb={2}>
              <MDTypography variant="h5">Rule description</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                {renderTips(ruleTips)}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableData} entriesPerPage={false} pagination={false} />
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <AccountCheckboxList
                ruleName="stop_loss_1"
                fetchApiEndpoint={`${API_URL}/api/rules/get_accounts_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card sx={{ width: "100%", overflow: "hidden", mb: 0 }}>
              <CampaignCheckboxList
                ruleName="stop_loss_1"
                fetchApiEndpoint={`${API_URL}/api/rules/get_campaigns_autorules/`}
                updateApiEndpoint={`${API_URL}/api/rules/update_rule_status/`}
                userRole={userRole}
                userEmail={userEmail}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Card sx={{ width: "100%", overflow: "hidden" }}>
              <DataTable table={tableDataAdsetLogs} canSearch entriesPerPage={false} />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* Edit Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={handleEditDialogClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            width: "60%",
            height: "70%",
          },
        }}
      >
        <>
          <DialogTitle>Edit Rule</DialogTitle>
          {selectedRow && (
            <DialogForm
              selectedRow={selectedRow}
              userRole={userRole}
              userName={userName}
              handleValueChange={handleValueChange}
              tempROI_A={tempROI_A}
              tempBudget={tempBudget}
              tempROI_F_1={tempROI_F_1}
              tempSpend={tempSpend}
              tempROI_F_2={tempROI_F_2}
              userROI_A={userROI_A}
              userBudget={userBudget}
              userROI_F_1={userROI_F_1}
              userSpend={userSpend}
              userROI_F_2={userROI_F_2}
              budgetROITips={renderTips(budgetROITips)}
              userROI_A_activate_campaign={userROI_A_activate_campaign}
              userROI_A_activate_adset={userROI_A_activate_adset}
              userL_1_budget={userL_1_budget}
              userROI_a_5_days={userROI_a_5_days}
              userL_1_spend={userL_1_spend}
              userABO_adset_spend_percent={userABO_adset_spend_percent}
              userABO_adset_roi_f={userABO_adset_roi_f}
              tempROI_a_activate_campaign={tempROI_a_activate_campaign}
              tempROI_a_activate_adset={tempROI_a_activate_adset}
              tempL_1_budget={tempL_1_budget}
              tempROI_a_5_days={tempROI_a_5_days}
              tempL_1_spend={tempL_1_spend}
              tempABO_adset_spend_percent={tempABO_adset_spend_percent}
              tempABO_adset_roi_f={tempABO_adset_roi_f}
            />
          )}
        </>
        <DialogActions>
          <Box display="flex" justifyContent="center" width="100%">
            <Button onClick={handleEditDialogClose} sx={{ marginRight: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default StopLoss;
