import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";

export const renderTips = (tipsList) => {
  return tipsList.map((item, key) => {
    const itemKey = `element-${key}`;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = item.split(urlRegex);

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {parts.map((part, index) => {
            if (urlRegex.test(part)) {
              return (
                <a
                  key={`link-${itemKey}-${index}`}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  {part}
                </a>
              );
            }

            // Иначе возвращаем обычный текст
            return <span key={`text-${itemKey}-${index}`}>{part}</span>;
          })}
        </MDTypography>
      </MDBox>
    );
  });
};
